export const WORDS = [
  'томар',
  'батыр',
  'халық',
  'алтын',
  'сезім',
  'құдай',
  'уақыт',
  'жүрек',
  'жырау',
  'сойыл',
  'сегіз',
  'дүние',
  'батыс',
  'жалын',
  'темір',
  'қайғы',
  'ханым',
  'жібек',
  'қоңыр',
  'бұлақ',
  'ғалым',
  'мырза',
  'қауіп',
  'жасын',
  'құлаш',
  'билік',
  'жазық',
  'кітап',
  'әрине',
  'үнемі',
  'әдемі',
  'оқушы',
  'әдеби',
  'спорт',
  'томар',
  'батыр',
  'рабай',
  'сомын',
  'құдай',
  'ескек',
  'үнпаз',
  'жүрек',
  'дабыс',
  'жырау',
  'сойыл',
  'сегіз',
  'батыс',
  'ныспы',
  'алтын',
  'шанақ',
  'жалын',
  'қамқа',
  'темір',
  'қайғы',
  'ханым',
  'жібек',
  'қоңыр',
  'шірік',
  'халық',
  'қарша',
  'бұлақ',
  'үлкен',
  'басқа',
  'сезім',
  'әбден',
  'бүкіл',
  'қызыл',
  'күнзе',
  'хабар',
  'пайда',
  'бұқар',
  'жасақ',
  'тоқал',
  'қылыш',
  'бұрын',
  'ешкім',
  'дүрия',
  'ымырт',
  'құдық',
  'сұрақ',
  'ындын',
  'жұпар',
  'құсшы',
  'месел',
  'сүйір',
  'барша',
  'тұмша',
  'босаң',
  'шұбар',
  'сәске',
  'ғайып',
  'момын',
  'қатер',
  'түтік',
  'дүлей',
  'қайыр',
  'науша',
  'іздеу',
  'киелі',
  'далап',
  'күшім',
  'дәлел',
  'тобық',
  'тұмар',
  'алаяқ',
  'кебін',
  'тұқым',
  'құйқа',
  'алаша',
  'жұмақ',
  'шұқыр',
  'тұрыс',
  'күтім',
  'құйын',
  'үндеу',
  'айлық',
  'кесім',
  'ғұрып',
  'үрпек',
  'бесін',
  'көсеу',
  'тоған',
  'ахуал',
  'бәтес',
  'кісен',
  'бұрғы',
  'мейір',
  'сәлде',
  'шолпы',
  'қарыз',
  'төсем',
  'қуғын',
  'кейіс',
  'пақыр',
  'тікен',
  'жәшік',
  'іскер',
  'аршын',
  'сайыс',
  'ынсап',
  'ақпар',
  'ишара',
  'айғыз',
  'бикеш',
  'ойшыл',
  'тігін',
  'мүдде',
  'аскөк',
  'матау',
  'майыр',
  'жарна',
  'сүлік',
  'тұрақ',
  'бодау',
  'өркен',
  'құнар',
  'бүйір',
  'шырша',
  'өксік',
  'дәйек',
  'асаба',
  'дәуіт',
  'бәрбі',
  'құдды',
  'мешел',
  'жөтел',
  'қыжым',
  'кеміс',
  'итаяқ',
  'бұғаз',
  'лепес',
  'тегеш',
  'бұқпа',
  'сусар',
  'айшық',
  'бұғат',
  'зағип',
  'кенші',
  'қоқыс',
  'тақыс',
  'жидек',
  'сауат',
  'шөкім',
  'аумақ',
  'бөгеу',
  'керік',
  'мазар',
  'бітік',
  'ұзату',
  'ныспы',
  'құйма',
  'сезік',
  'ақиық',
  'қалыс',
  'бәліш',
  'мүшел',
  'ылғал',
  'тауан',
  'ботқа',
  'дұғай',
  'қисын',
  'десте',
  'қабат',
  'жайын',
  'ұштап',
  'далда',
  'ырғақ',
  'күзеу',
  'құқай',
  'төсек',
  'арбау',
  'бұйым',
  'ұршық',
  'дидар',
  'жұбай',
  'замат',
  'иегер',
  'кекіл',
  'борыш',
  'маман',
  'тауар',
  'науат',
  'ашылу',
  'кежір',
  'қамау',
  'дүйім',
  'кірпі',
  'орама',
  'себет',
  'тарақ',
  'шатақ',
  'кеуек',
  'бекет',
  'мүдір',
  'ымыра',
  'безеу',
  'ілмек',
  'құбыр',
  'үлпек',
  'жілік',
  'көмей',
  'әнтек',
  'тозық',
  'уағыз',
  'қазба',
  'үшкір',
  'айуан',
  'басыр',
  'доғал',
  'епсіз',
  'жамау',
  'бақша',
  'жапан',
  'кеден',
  'лүпіл',
  'марқа',
  'несие',
  'бөлік',
  'ұдайы',
  'ұйтқы',
  'әсіре',
  'апорт',
  'сарын',
  'орман',
  'сәтен',
  'қоныс',
  'ноқат',
  'бұзау',
  'көрші',
  'жарық',
  'бауыр',
  'нобай',
  'ақкіс',
  'кәуап',
  'жетім',
  'әулет',
  'нәрсе',
  'оқиға',
  'құпия',
  'жоңқа',
  'кіреу',
  'зәуде',
  'жауын',
  'бейім',
  'мінез',
  'қаріп',
  'күйеу',
  'жайық',
  'ауқат',
  'қарыс',
  'ыңғай',
  'қайың',
  'жесір',
  'бәден',
  'тұнба',
  'бүлік',
  'қауым',
  'ұшқын',
  'мұрат',
  'бөлме',
  'аруақ',
  'дария',
  'күмән',
  'сүйек',
  'ұлтан',
  'жігер',
  'сурет',
  'адуын',
  'шадыр',
  'тұлым',
  'нөкер',
  'ғұмыр',
  'биязы',
  'қайын',
  'басшы',
  'жеміс',
  'көрпе',
  'өңмен',
  'ғашық',
  'бөгде',
  'мұрын',
  'шатыр',
  'әулие',
  'бөгет',
  'қойшы',
  'сүрең',
  'жібек',
  'өркеш',
  'жеңіс',
  'аңғал',
  'мұрша',
  'соқыр',
  'ұрпақ',
  'болыс',
  'сауыт',
  'уайым',
  'қоғам',
  'тамыз',
  'айғыр',
  'сиқыр',
  'тұлға',
  'ұрлық',
  'сәуле',
  'тамшы',
  'мүсін',
  'шілде',
  'көсем',
  'бұзық',
  'дөкір',
  'төбет',
  'қимыл',
  'күзен',
  'оймақ',
  'құрбы',
  'доңыз',
  'қоңсы',
  'мүйіз',
  'тоғай',
  'құзар',
  'монша',
  'жорық',
  'нәпсі',
  'тұзақ',
  'алғыс',
  'молда',
  'шұбақ',
  'айтыс',
  'таспа',
  'шырай',
  'қария',
  'рәсуа',
  'көбік',
  'тасшы',
  'облыс',
  'пейіл',
  'реніш',
  'құман',
  'шабын',
  'астық',
  'дабыл',
  'дорба',
  'жолақ',
  'кәсіп',
  'мешіт',
  'мұхит',
  'шабыт',
  'хатшы',
  'дүбір',
  'масыл',
  'торай',
  'бипаз',
  'сипат',
  'шалғы',
  'тәлім',
  'таным',
  'қаржы',
  'сақшы',
  'жауыз',
  'кейіп',
  'шаңғы',
  'сөкет',
  'түбек',
  'тұсау',
  'уәкіл',
  'бұрыш',
  'елдік',
  'сүтші',
  'жүйке',
  'нөпір',
  'түнек',
  'мирас',
  'тірек',
  'қорек',
  'тырна',
  'бүгін',
  'қырау',
  'малшы',
  'қауын',
  'күрте',
  'шопан',
  'бесік',
  'шодыр',
  'бәйге',
  'ізбес',
  'күбір',
  'лебіз',
  'түйір',
  'диқан',
  'шырақ',
  'құрал',
  'үйрек',
  'бақсы',
  'думан',
  'жүлде',
  'залым',
  'қобыз',
  'түрме',
  'тұман',
  'нойыс',
  'көпір',
  'негіз',
  'сыңар',
  'үңгір',
  'ұстаз',
  'өрнек',
  'мысал',
  'тәсіл',
  'мұнай',
  'нүкте',
  'табыс',
  'дәуір',
  'мәнер',
  'ноқта',
  'пәтер',
  'сұйық',
  'тұран',
  'нұсқа',
  'шыдам',
  'үстел',
  'жақұт',
  'сирақ',
  'тақия',
  'ықпал',
  'үстеу',
  'өрмек',
  'сұлба',
  'білек',
  'күйші',
  'әскер',
  'тұғыр',
  'желім',
  'нарық',
  'сызба',
  'тұмау',
  'тәңір',
  'қашыр',
  'өсиет',
  'азғын',
  'зейін',
  'дәліз',
  'жусан',
  'күріш',
  'түлек',
  'мұржа',
  'төлем',
  'үрдіс',
  'айбын',
  'дәнек',
  'жарыс',
  'заңшы',
  'түйме',
  'нымша',
  'мәйіт',
  'оташы',
  'мәтел',
  'нәзір',
  'пияла',
  'нәсіп',
  'пойыз',
  'нөсер',
  'шұжық',
  'әмиян',
  'ұнтақ',
  'оқтау',
  'ризық',
  'құрма',
  'соғым',
  'қоспа',
  'ғұсыл',
  'індет',
  'сірне',
  'шүмек',
  'сүйел',
  'жазба',
  'пайыз',
  'сәбіз',
  'сөмке',
  'өлшем',
  'тоқаш',
  'сүзгі',
  'құжат',
  'тізбе',
  'дарын',
  'белгі',
  'тұлып',
  'салық',
  'әмісе',
  'төзім',
  'хикая',
  'шабыс',
  'түйін',
  'қадір',
  'бағыт',
  'шапқы',
  'ызғар',
  'аштық',
  'сүлгі',
  'шұбат',
  'сүзбе',
  'сыңсу',
  'ғарыш',
  'сынақ',
  'шұлық',
  'қамыр',
  'ілгек',
  'сағыз',
  'рәсім',
  'керім',
  'пәлте',
  'жинақ',
  'дүкен',
  'пиғыл',
  'көңіл',
  'дауыс',
  'күміс',
  'ұсқын',
  'шылау',
  'әлпет',
  'нәсіл',
  'екпін',
  'нышан',
  'серік',
  'сүйеу',
  'серік',
  'ширек',
  'ойпат',
  'зұлым',
  'тесік',
  'содыр',
  'ісмер',
  'қайшы',
  'құрық',
  'бөрік',
  'түлкі',
  'нақыл',
  'көрме',
  'үйшік',
  'дақыл',
  'тырма',
  'дүсір',
  'шумақ',
  'ұйқас',
  'өштік',
  'жосық',
  'алтын',
  'сойыл',
  'дүние',
  'қалет',
]