export const VALIDGUESSES = [
'деген',
'қазақ',
'бірақ',
'болып',
'болса',
'кейін',
'керек',
'болды',
'келіп',
'қалды',
'қарай',
'қарсы',
'кетті',
'батыр',
'қалың',
'кезде',
'патша',
'менің',
'қалай',
'берді',
'кенет',
'қазір',
'басын',
'бұрын',
'үлкен',
'алмай',
'келді',
'жақсы',
'жатыр',
'туған',
'қоқан',
'басқа',
'алған',
'шығып',
'қарап',
'сонда',
'дейін',
'шықты',
'өзіне',
'түсіп',
'түсті',
'қайта',
'қасым',
'барып',
'айтып',
'жігіт',
'салып',
'болар',
'тіпті',
'әбден',
'басып',
'маған',
'жүріп',
'жерде',
'жерге',
'бүкіл',
'бірге',
'көріп',
'беріп',
'бұлар',
'бүгін',
'жауап',
'дұрыс',
'дейді',
'ұстап',
'халық',
'жүсіп',
'өткен',
'шығар',
'қанша',
'мынау',
'кетіп',
'бұдан',
'қызыл',
'бөтен',
'қайда',
'қойды',
'қатты',
'содан',
'алтын',
'тұрып',
'бізге',
'соңғы',
'сенің',
'әскер',
'әрине',
'көшіп',
'сегіз',
'қырық',
'хабар',
'түнде',
'күміс',
'кіріп',
'біреу',
'жатып',
'бұған',
'жаққа',
'сөзін',
'алады',
'соның',
'қолын',
'қолға',
'қатын',
'елдің',
'екеуі',
'көзін',
'пайда',
'бойын',
'көрді',
'бәрін',
'артық',
'арада',
'жылқы',
'күнде',
'жетіп',
'қанды',
'солай',
'бұқар',
'сәтте',
'бұның',
'жақын',
'сонау',
'ахмет',
'құдай',
'жетті',
'кейде',
'жерін',
'былай',
'ертең',
'уақыт',
'даусы',
'қатар',
'түгіл',
'қосып',
'көзге',
'жүрек',
'біліп',
'бірін',
'саған',
'төмен',
'түрлі',
'қашып',
'тұрды',
'жасақ',
'келсе',
'соғыс',
'тегіс',
'тауып',
'еткен',
'алуға',
'есіне',
'күлді',
'талай',
'шыдай',
'әкесі',
'күтіп',
'табын',
'көңіл',
'басты',
'ондай',
'тоқал',
'ертіп',
'ойлап',
'жырау',
'қалып',
'сайын',
'батыс',
'еліне',
'дүние',
'сойыл',
'қылыш',
'түгел',
'қылып',
'салық',
'біраз',
'темір',
'жеңіл',
'әйтсе',
'әлсін',
'қойып',
'бопай',
'естіп',
'жолға',
'жүзге',
'іштей',
'қытай',
'тоғыз',
'жылғы',
'ешкім',
'тыныш',
'қайғы',
'бірде',
'мұның',
'жаман',
'күшті',
'қызын',
'болуы',
'жасыл',
'жинап',
'күнге',
'аяғын',
'шағын',
'жазып',
'менен',
'өңдеу',
'алмас',
'сөзді',
'найза',
'айтты',
'заман',
'малын',
'қағып',
'ханым',
'сөзге',
'қызық',
'етеді',
'жатты',
'сезім',
'қашан',
'әдейі',
'бөлек',
'алтай',
'ақтау',
'қысқа',
'майор',
'шақты',
'арман',
'асқан',
'соған',
'жетер',
'жібек',
'садақ',
'құрып',
'бұхар',
'кірді',
'кілең',
'себеп',
'бөліп',
'қоңыр',
'кітап',
'қояды',
'ағзам',
'келер',
'қырып',
'аттан',
'жасар',
'мұнда',
'оңаша',
'істеп',
'түлкі',
'аздан',
'батып',
'аппақ',
'берік',
'бидің',
'жасап',
'толық',
'ғылым',
'дауыс',
'көзді',
'талап',
'халқы',
'өлген',
'баяғы',
'бетін',
'жолын',
'мырза',
'өскен',
'үнемі',
'ұстай',
'сұрап',
'құлақ',
'дайын',
'жақта',
'соғып',
'жылап',
'тиген',
'ыстық',
'қауіп',
'алмақ',
'арғын',
'барды',
'жылан',
'қоныс',
'ешбір',
'ақыры',
'жасын',
'жерді',
'ашулы',
'қамын',
'үйіне',
'мықты',
'жалпы',
'жалын',
'ойына',
'сатып',
'сосын',
'теріс',
'еркек',
'еркін',
'санда',
'ақылы',
'бірте',
'ойнап',
'күліп',
'үйдің',
'әттең',
'қағаз',
'шақта',
'құмар',
'жарық',
'аулақ',
'мүлде',
'жауып',
'құлаш',
'билік',
'жайып',
'інісі',
'мойын',
'қонып',
'асыға',
'жазық',
'шешім',
'кедей',
'мекен',
'ашқан',
'екені',
'мейлі',
'қазан',
'жайық',
'жарым',
'князь',
'бұлай',
'басым',
'кеңес',
'тәуке',
'әлден',
'жарып',
'бітім',
'бұрып',
'ислам',
'ортақ',
'жайын',
'жұмыс',
'тапты',
'өзбек',
'қажет',
'сізге',
'үшеуі',
'тілін',
'қанат',
'деуге',
'кетсе',
'орман',
'тұрса',
'жақты',
'бойлы',
'нағыз',
'босқа',
'депті',
'казак',
'санап',
'сыбан',
'кімге',
'болат',
'тарта',
'қамал',
'бетпе',
'ерлік',
'сонан',
'қонақ',
'бергі',
'бұзау',
'жағын',
'кезек',
'көрші',
'үнсіз',
'дереу',
'жүзді',
'келте',
'күрең',
'әрбір',
'арасы',
'мақұл',
'құран',
'бауыр',
'жетім',
'шекті',
'өзара',
'нәрсе',
'шебер',
'әуелі',
'бетке',
'шұбар',
'бекер',
'билеп',
'қатып',
'жауды',
'күшік',
'түсер',
'төтеп',
'аузын',
'өткір',
'дулат',
'жанар',
'жылда',
'жылға',
'сауда',
'сыбыс',
'қыран',
'қысып',
'құпия',
'аунап',
'елден',
'көрсе',
'алдын',
'ауған',
'бұлақ',
'жарты',
'қызды',
'жеңіп',
'оқиға',
'қолды',
'айқай',
'жедел',
'жесір',
'сәлем',
'талды',
'тегін',
'десең',
'тілек',
'тәтті',
'ғажап',
'алдап',
'күйде',
'терең',
'қамшы',
'айдай',
'барақ',
'жанды',
'зекет',
'керей',
'туады',
'күйіп',
'сорлы',
'білсе',
'жауын',
'жұмыр',
'сырлы',
'тасты',
'қамыс',
'ұлтан',
'аңдып',
'билер',
'ойдым',
'шығыс',
'пұшық',
'таныс',
'айқын',
'алуан',
'алшын',
'кімде',
'білім',
'еңбек',
'жалда',
'таман',
'жалаң',
'кесіп',
'мінез',
'түнгі',
'үнмен',
'әдемі',
'апасы',
'үйсін',
'берем',
'бетер',
'мәлім',
'мамық',
'тарих',
'алуды',
'атына',
'кешке',
'танып',
'қабат',
'қырқа',
'басар',
'жатса',
'саран',
'сұсты',
'қалпы',
'қатал',
'өтеді',
'атасы',
'бірен',
'жолды',
'күйін',
'жүзін',
'саяси',
'түсін',
'төсек',
'жүнді',
'тұста',
'қымыз',
'жүдеп',
'кеуде',
'күнгі',
'сипап',
'ұқсас',
'алсаң',
'жаным',
'жоқта',
'қисық',
'айтар',
'демек',
'күйеу',
'намыс',
'үкілі',
'алсын',
'балақ',
'жолда',
'оқалы',
'алғаш',
'кекті',
'мұрты',
'нақты',
'сарай',
'құлан',
'абзал',
'бағып',
'менде',
'торқа',
'шашын',
'істеу',
'айқас',
'алдым',
'аттың',
'ашуға',
'жайлы',
'мәңгі',
'оқтын',
'серік',
'сәске',
'түйіп',
'шешіп',
'қарыс',
'астам',
'жорға',
'күлкі',
'кәміл',
'ойлар',
'теңіз',
'түлік',
'қайың',
'есаул',
'кешкі',
'пенде',
'сенім',
'судай',
'табан',
'түрде',
'шаруа',
'қарым',
'бөлме',
'заңды',
'киген',
'татар',
'хатты',
'арлан',
'бүлік',
'бөрік',
'жолғы',
'мүлік',
'толып',
'шегір',
'шоқша',
'бақыт',
'кетер',
'көмек',
'шапан',
'қашты',
'жолай',
'күнді',
'сирек',
'сәнді',
'қауым',
'ұшқын',
'аспан',
'етуге',
'кімді',
'малды',
'мезет',
'төніп',
'аямай',
'аяулы',
'оймен',
'сынып',
'тамақ',
'бірер',
'десем',
'деуші',
'мамыр',
'тарту',
'тынық',
'түсте',
'ғайып',
'қабыл',
'айдын',
'жазғы',
'жасқа',
'мұрат',
'санын',
'тұтас',
'төгіп',
'ханды',
'шейін',
'қайсы',
'үстін',
'аруақ',
'күзгі',
'сараң',
'айтшы',
'дария',
'күмән',
'күшін',
'лашын',
'ойбай',
'салты',
'қадам',
'сақал',
'сүйек',
'талан',
'тақыр',
'әдісі',
'әлсіз',
'алпыс',
'білте',
'жігер',
'жүрер',
'намаз',
'сонша',
'сурет',
'шешті',
'қуаты',
'қурай',
'бұзып',
'бөлім',
'дауыл',
'кілем',
'матап',
'момын',
'сабыр',
'семей',
'түнеп',
'ымырт',
'құдық',
'белгі',
'білем',
'кезең',
'кезін',
'нөкер',
'қосты',
'қулық',
'өмірі',
'аттай',
'малға',
'мақал',
'ойран',
'ресми',
'ретте',
'түкті',
'шолақ',
'ғұмыр',
'балық',
'белді',
'солды',
'сұрақ',
'талас',
'шүкір',
'жасау',
'көкек',
'лайық',
'тыйым',
'қайын',
'қанық',
'қойын',
'басшы',
'билеу',
'қатер',
'құмды',
'айдан',
'жазда',
'жекпе',
'жеміс',
'көрпе',
'міреу',
'тарай',
'теңге',
'түпкі',
'шолып',
'ғашық',
'қапты',
'құрту',
'артын',
'жирен',
'күрес',
'кәрлі',
'мыңға',
'назар',
'сапар',
'тараж',
'шарап',
'шаршы',
'ғалым',
'қотан',
'құшақ',
'араша',
'атағы',
'иіліп',
'күрке',
'мұрын',
'ойдан',
'оқушы',
'парыз',
'шатыр',
'қалап',
'әулие',
'бәлем',
'бөгет',
'жыршы',
'келуі',
'сапты',
'тілді',
'шешен',
'қалам',
'қойшы',
'өркеш',
'аттар',
'жеңіс',
'кигіз',
'семіз',
'қарға',
'өнері',
'аспай',
'дедің',
'дүлей',
'кадет',
'маңқа',
'мешін',
'неден',
'пышақ',
'сабақ',
'сенат',
'тауық',
'тымақ',
'шалып',
'өседі',
'жақып',
'күлім',
'минут',
'қалуы',
'қылаң',
'артқы',
'арқан',
'мінді',
'мұрша',
'қасқа',
'үйлер',
'артта',
'баста',
'жазым',
'күллі',
'көпке',
'нашар',
'ойлай',
'соқыр',
'судан',
'сөйле',
'үркер',
'ұрпақ',
'асыра',
'байқа',
'бетте',
'болыс',
'гірім',
'душар',
'жұпар',
'мұнан',
'сауыт',
'сынды',
'сырға',
'тайпа',
'тарап',
'таңба',
'уайым',
'қатаң',
'қоғам',
'қызба',
'ұстау',
'өңшең',
'ақсақ',
'десіп',
'дыбыс',
'зират',
'күзде',
'көгал',
'тыным',
'тұтып',
'төгіс',
'шашып',
'аңқау',
'ойдың',
'оқтап',
'сойып',
'сүйіп',
'тамыз',
'түрін',
'шыбын',
'шығуы',
'шұғыл',
'қайшы',
'құлжа',
'құрлы',
'үніне',
'аздап',
'айғыр',
'аяқты',
'ақшыл',
'батыл',
'биязы',
'бұзар',
'есебі',
'көкше',
'мәрте',
'надан',
'сағат',
'сиқыр',
'сәуле',
'текке',
'тұлға',
'істің',
'қабан',
'қабақ',
'қарау',
'ұрлық',
'алсақ',
'бұғау',
'дерек',
'диуан',
'жанай',
'жасай',
'манап',
'сүйір',
'тамшы',
'топқа',
'тыңшы',
'әркім',
'арсыз',
'балаң',
'жасты',
'жатар',
'жоққа',
'жымия',
'мысық',
'мүсін',
'совет',
'терге',
'шабар',
'шығын',
'шілде',
'құрды',
'құрыш',
'айдың',
'амалы',
'астан',
'жабық',
'жарақ',
'иесіз',
'көрік',
'көсем',
'тайлы',
'шойын',
'қайыр',
'құтты',
'айран',
'бекем',
'беріс',
'бұзық',
'бұнда',
'жайды',
'күзет',
'көрем',
'оқшау',
'сағым',
'тегіл',
'топты',
'түске',
'ғасыр',
'қалаш',
'қуана',
'қырлы',
'арқар',
'асқар',
'аңғал',
'бағын',
'бойды',
'заңға',
'майда',
'майлы',
'науан',
'нәзік',
'сасық',
'сыншы',
'сүйеп',
'төбет',
'хадис',
'шеткі',
'қария',
'қақты',
'қыста',
'құнын',
'анасы',
'беруі',
'бозаң',
'бұлаң',
'елсіз',
'ерлер',
'жүріс',
'күйік',
'лезде',
'мүлкі',
'оқыды',
'салпы',
'сипай',
'тәуір',
'қашық',
'қимыл',
'қоныш',
'қотыр',
'алысу',
'асқақ',
'келін',
'кепіл',
'күзен',
'көпті',
'мидай',
'мұнша',
'оймақ',
'сауық',
'сұрау',
'тобын',
'қарар',
'қолма',
'қоюға',
'құрбы',
'барша',
'бүйте',
'доңыз',
'дүрия',
'жоқты',
'жүзік',
'мүйіз',
'мәтін',
'сынап',
'тоғай',
'туысы',
'түбіт',
'хатта',
'ызалы',
'қанда',
'қарын',
'қисса',
'ұшқыр',
'аталы',
'атауы',
'ашады',
'балта',
'босаң',
'енген',
'етуді',
'жорық',
'жырық',
'жөнді',
'көлік',
'нәпсі',
'пішін',
'сусын',
'тарам',
'таңғы',
'тығыз',
'түрге',
'тұзақ',
'шақыр',
'іздеу',
'қоңсы',
'құрса',
'үйрен',
'алғыс',
'атпен',
'аяныш',
'барам',
'бірді',
'жарға',
'жүзде',
'жөнін',
'кешір',
'күнін',
'лақап',
'молда',
'перде',
'сұрай',
'таңда',
'терін',
'түсім',
'тұнық',
'тұсап',
'құлын',
'азбан',
'айтыс',
'барыс',
'бағзы',
'босып',
'ермек',
'жалау',
'жиған',
'жібер',
'жұбын',
'зерлі',
'киелі',
'класс',
'күпті',
'көбін',
'ораза',
'пенен',
'рақым',
'сарын',
'таспа',
'таулы',
'тілге',
'халге',
'шетін',
'шырай',
'қадап',
'қаным',
'қыбыр',
'құнды',
'құқық',
'аласы',
'албан',
'асыру',
'бітеу',
'бүтін',
'ердің',
'жарап',
'жария',
'жырға',
'зорға',
'кәкір',
'көбік',
'көкем',
'мұрны',
'облыс',
'пейіл',
'рақат',
'реніш',
'серке',
'талып',
'тақта',
'тойын',
'топыр',
'шашау',
'шелек',
'шоқан',
'шулап',
'қамап',
'қақпа',
'құман',
'өрісі',
'өшпес',
'ағалы',
'ақпан',
'жинау',
'зәрлі',
'көкте',
'мәзір',
'сотқа',
'сәтті',
'текте',
'түйін',
'түлей',
'шағым',
'шымыр',
'қайқы',
'қолаң',
'қылды',
'құрық',
'өнеге',
'айғақ',
'анада',
'астық',
'ашуды',
'білер',
'білуі',
'бұжыр',
'бөкен',
'дабыл',
'дорба',
'елдер',
'ессіз',
'жолақ',
'кенже',
'күйлі',
'күшім',
'кәсіп',
'көкті',
'көміп',
'көрме',
'мейіз',
'мешіт',
'мұхит',
'папка',
'пысық',
'таста',
'тісті',
'түркі',
'тұйық',
'төлеу',
'ханша',
'хатшы',
'шабыт',
'шалыс',
'қалта',
'қарша',
'қинап',
'қорқу',
'қосар',
'қылық',
'үлесі',
'ұрлап',
'әдеби',
'аласа',
'алшақ',
'ауруы',
'белес',
'бірлі',
'бұраң',
'дүбір',
'ермей',
'көген',
'масыл',
'ноқай',
'ойрат',
'сипат',
'соққы',
'сырты',
'сырым',
'сөзің',
'тапал',
'торлы',
'тырыс',
'түтін',
'тәжім',
'тәлім',
'шөлді',
'қадір',
'қайық',
'қыңыр',
'алуда',
'артып',
'базар',
'бейне',
'жемін',
'жорта',
'жыбыр',
'жырды',
'жүген',
'зұлым',
'иесін',
'кесек',
'лағыл',
'малай',
'мінер',
'ойыны',
'ортан',
'сазды',
'таным',
'тағам',
'тыныс',
'тысты',
'тілеу',
'түрік',
'төбел',
'шағыр',
'ызғар',
'қаржы',
'қорға',
'құрым',
'үстем',
'әрмен',
'өйтіп',
'айқыш',
'арзан',
'аудан',
'аусыл',
'берлі',
'бозды',
'бүйту',
'бұйда',
'бөксе',
'дәлел',
'екілі',
'еркем',
'жайма',
'жауыз',
'жыпыр',
'залда',
'заңда',
'иттей',
'кейіп',
'кірпі',
'лашық',
'лөкет',
'небір',
'ойнас',
'рабат',
'роман',
'санды',
'сақау',
'сақшы',
'сумаң',
'сусыз',
'сөкет',
'тарпа',
'тобық',
'тоқта',
'түбек',
'тұсау',
'уәкіл',
'шимай',
'ынжық',
'қарлы',
'қиғаш',
'қопақ',
'қынды',
'қысым',
'құрақ',
'үштен',
'ұйқыш',
'астын',
'байып',
'бұрыш',
'бұғып',
'дүкен',
'елдік',
'естен',
'есімі',
'жалма',
'жүйке',
'зауал',
'иісті',
'кілті',
'кәпір',
'мазақ',
'медеу',
'нөпір',
'пайыз',
'парсы',
'сынық',
'табам',
'тасыр',
'тесік',
'типыл',
'тобыр',
'тыпыр',
'түнек',
'түсуі',
'тұмар',
'тәубе',
'шарты',
'ілияс',
'қанып',
'қолда',
'құжат',
'атлас',
'боран',
'бөгде',
'газет',
'ептеп',
'жапар',
'жылас',
'зарлы',
'майса',
'мирас',
'нышан',
'нұрлы',
'пішен',
'пәлен',
'салма',
'сонар',
'судың',
'сызық',
'сүйем',
'текті',
'тыңда',
'тілде',
'тірек',
'шайын',
'қасын',
'қасық',
'қорек',
'қыпша',
'алаяқ',
'байыз',
'берен',
'бетті',
'бидай',
'бопты',
'бітер',
'бұйра',
'демде',
'енжар',
'ереже',
'жымың',
'жырын',
'жүкті',
'зиялы',
'кебін',
'кешті',
'мығым',
'ноғай',
'оннан',
'пікір',
'рулық',
'сабын',
'сақта',
'табақ',
'тілім',
'тұқым',
'химия',
'шоғыр',
'қарны',
'қарып',
'қосшы',
'қоқыр',
'қырау',
'қысқы',
'құдды',
'айтса',
'алаңы',
'алқап',
'бағам',
'бағым',
'бурыл',
'бәлки',
'дарын',
'етене',
'жамбы',
'жанат',
'жарау',
'жерік',
'жолым',
'жұмақ',
'жұтым',
'жәбір',
'зәбір',
'кенеп',
'малшы',
'отыру',
'почта',
'режим',
'сылап',
'сықақ',
'тасын',
'толас',
'тосын',
'тоқты',
'тықыр',
'тіреу',
'түйек',
'тұяғы',
'хакім',
'шаңды',
'шегін',
'шұқыр',
'ынтық',
'ырғай',
'ішпей',
'қажып',
'қайып',
'қауын',
'құйын',
'ұрсып',
'айлық',
'аймақ',
'алушы',
'артқа',
'аштың',
'баулы',
'бағыт',
'бесік',
'бәйге',
'бәйек',
'жазба',
'жаста',
'жиыны',
'жүгін',
'кеңсе',
'күбір',
'күйме',
'көсір',
'лебіз',
'майды',
'мұңлы',
'нысан',
'ойлан',
'оқиды',
'оқпен',
'сарып',
'сасыр',
'тажал',
'тамып',
'тамұқ',
'тонды',
'турап',
'түйір',
'тәжік',
'уақты',
'уытты',
'фиғыл',
'шиелі',
'ыңғай',
'інілі',
'қараң',
'қасты',
'қоюшы',
'қырын',
'қысыр',
'азалы',
'айнал',
'алтау',
'ашыла',
'безер',
'демеу',
'диқан',
'дәлме',
'жарлы',
'жасым',
'желік',
'жілік',
'жүдеу',
'жұман',
'жұмар',
'залал',
'зілді',
'карта',
'кезеп',
'кесел',
'кесім',
'кесір',
'мадақ',
'оралу',
'пәтуа',
'санау',
'сатыр',
'сауал',
'сауым',
'сауға',
'сопақ',
'сорпа',
'сусар',
'сыңай',
'тамыр',
'танау',
'тоқым',
'тоқып',
'тізім',
'тұтқа',
'харап',
'шақша',
'шетте',
'ширақ',
'шоғын',
'шырақ',
'шытын',
'ілігу',
'ғазиз',
'ғұрып',
'қабір',
'қарық',
'қаріп',
'қырда',
'құрал',
'үзбей',
'үйрек',
'үрпек',
'ұстар',
'әумин',
'адрес',
'айрыл',
'айына',
'апару',
'атпай',
'аңдау',
'баған',
'бақсы',
'бақыр',
'бекет',
'бесін',
'датқа',
'дауды',
'дуалы',
'думан',
'дутар',
'дінге',
'жайра',
'жалап',
'жалқы',
'жатуы',
'желпі',
'жеңге',
'жүгір',
'жүлде',
'залым',
'кексе',
'кенді',
'кесте',
'кішік',
'көрін',
'ләйім',
'малма',
'машық',
'маңай',
'мүрит',
'нәлет',
'ойпат',
'орхон',
'поляк',
'реңді',
'рәсуа',
'самай',
'содыр',
'созым',
'сырық',
'сықыр',
'сұлық',
'сұтыр',
'тарын',
'таған',
'тағын',
'тезек',
'тонау',
'тоған',
'түбін',
'тұсыр',
'тұқыл',
'төтен',
'уәзір',
'шашақ',
'қапас',
'қарғы',
'қағыс',
'қобыз',
'қодас',
'құмай',
'құмда',
'ұйғыр',
'айдар',
'алқым',
'арабы',
'арнай',
'асады',
'атжал',
'ахуал',
'ақкіс',
'ақкөз',
'ақсын',
'байым',
'бапты',
'бедеу',
'безбе',
'берме',
'бітеп',
'бұрыл',
'бұрыс',
'бәтес',
'бөрте',
'дамуы',
'дамыл',
'дарақ',
'дауым',
'демал',
'едіге',
'есуас',
'жабыс',
'жаныс',
'жарғы',
'жасыр',
'жатақ',
'жегіп',
'желіс',
'жылым',
'жыныс',
'жығар',
'замға',
'зауза',
'иірім',
'кісен',
'күйсе',
'кәрия',
'көжек',
'көкір',
'көтек',
'лайлы',
'леген',
'малта',
'мақам',
'мейір',
'мұңды',
'мәліш',
'нойыс',
'округ',
'отаны',
'оюлан',
'пісер',
'сажын',
'сауап',
'сидаң',
'сиқым',
'сызат',
'сынау',
'сұрас',
'сәлде',
'тазша',
'тарат',
'тағат',
'тақай',
'тігім',
'тірер',
'түрді',
'түрке',
'түрме',
'түсір',
'тұзды',
'тұйқы',
'тұман',
'тұруы',
'тұсақ',
'тәңкі',
'төсеп',
'уқала',
'хатым',
'шайып',
'шалын',
'шарша',
'шатыс',
'шеген',
'шенді',
'шетен',
'шиқан',
'шолпы',
'шрифт',
'шыдау',
'шылау',
'шымши',
'шытыр',
'ыңғыл',
'ілулі',
'істен',
'қарыз',
'қауыс',
'қожақ',
'қомды',
'қоста',
'қосыл',
'қуғын',
'қырыл',
'құбыл',
'құнан',
'үздік',
'үйлік',
'үйтіп',
'ұятты',
'әкімі',
'әсері',
'өкілі',
'өлшем',
'өтуге',
'өтуін',
'өтіну',
'агент',
'аттас',
'баспа',
'дамай',
'ернек',
'жабай',
'жазуы',
'жалба',
'желді',
'кейіс',
'күпір',
'көпір',
'медет',
'мәнді',
'негіз',
'нысап',
'ойнақ',
'оқыту',
'пақыр',
'самал',
'стиль',
'схема',
'сыңар',
'сөйте',
'талақ',
'талма',
'тамам',
'тақап',
'тақау',
'толқу',
'тікен',
'шешек',
'шұбақ',
'шұнақ',
'ықшам',
'іскер',
'ғақыл',
'қайым',
'құлқы',
'үйшік',
'үңгір',
'ұстаз',
'өрнек',
'айбат',
'айыру',
'айғай',
'архив',
'атқыш',
'ақыны',
'бадам',
'бағар',
'бақша',
'бейіт',
'белок',
'дабыр',
'дамып',
'діріл',
'дүсір',
'жадап',
'жатын',
'желек',
'жеміт',
'жынды',
'зулап',
'кетпе',
'кетік',
'кешек',
'көпте',
'лаула',
'мысал',
'мұнай',
'нүкте',
'нөмір',
'ойпаң',
'расул',
'сайыс',
'сейіл',
'секем',
'табуы',
'табыс',
'тапап',
'тарау',
'терім',
'тозаң',
'тыста',
'ферма',
'шақар',
'шумақ',
'ынсап',
'істес',
'қаулы',
'қисап',
'қырғи',
'ұлтты',
'абажа',
'адыра',
'айбар',
'алдан',
'ауаға',
'аядай',
'ақпар',
'гүлді',
'дедік',
'дәрет',
'дәуір',
'егжей',
'енеді',
'енсіз',
'жапан',
'жаңқа',
'жекке',
'желең',
'жылау',
'жылын',
'жырым',
'жұлын',
'игеру',
'иттен',
'ишара',
'кебіс',
'келіс',
'кемел',
'керең',
'кесер',
'күйді',
'мерей',
'молла',
'мәнер',
'ноқта',
'нұсқа',
'озбыр',
'пайым',
'парша',
'пішіп',
'пәтер',
'сағыр',
'сұйық',
'талқы',
'тұран',
'тұғыр',
'тәсіл',
'уәжіп',
'фильм',
'форма',
'хауыз',
'шағыл',
'шаққа',
'шетел',
'ширек',
'шошақ',
'шошқа',
'шылым',
'шырын',
'шөпші',
'інжіл',
'ішпек',
'қажыр',
'қазық',
'қайыс',
'қалау',
'қапан',
'қаңқу',
'қимас',
'қиқар',
'қунақ',
'қызым',
'құмақ',
'үстел',
'үшкіл',
'әулет',
'өлшеу',
'өсіру',
'өшіру',
'адуын',
'айдау',
'айлар',
'айтпа',
'айғыз',
'алдау',
'алғыр',
'арнау',
'арқау',
'аулау',
'аумин',
'балқы',
'бебеу',
'бедер',
'бейім',
'бикеш',
'будақ',
'бөліс',
'газды',
'даулы',
'дақыл',
'допты',
'дұбыр',
'дәмді',
'дөнен',
'емшек',
'жадау',
'жайша',
'жағал',
'жақұт',
'жемей',
'жидым',
'жұлба',
'жұлма',
'жұмса',
'затты',
'күйкі',
'күлің',
'көгіс',
'көмеш',
'мылжа',
'мүдде',
'мәнін',
'нарын',
'неміс',
'ноқат',
'озмыш',
'пакет',
'пиғыл',
'сабан',
'сазан',
'сапта',
'сауын',
'серең',
'сирақ',
'сотта',
'спорт',
'сумен',
'сығыр',
'сірке',
'сіңір',
'сұлба',
'сәкен',
'тақия',
'текше',
'тозақ',
'торап',
'тымық',
'тілің',
'түтек',
'тұрақ',
'төргі',
'шарбы',
'шоқай',
'шықыр',
'шөкім',
'ықпал',
'ғайри',
'ғалам',
'ғарық',
'ғаріп',
'қалқа',
'қораш',
'қорен',
'қорын',
'қуқыл',
'үстап',
'үстеу',
'ұятқа',
'ұқсап',
'әйтіп',
'әсіре',
'өркен',
'өрмек',
'өңдес',
'абиыр',
'автор',
'адасу',
'азулы',
'аптап',
'аяқта',
'аңсар',
'аңыра',
'балды',
'бақан',
'бедел',
'билет',
'борша',
'борыш',
'босат',
'білек',
'бүйір',
'бүкір',
'бөлік',
'гараж',
'дерті',
'дойбы',
'дудар',
'дүрсе',
'дәйек',
'дәріс',
'дөңес',
'ездік',
'езіне',
'емдеу',
'емдік',
'ербең',
'еңшең',
'жалшы',
'жаран',
'желеп',
'желім',
'жетік',
'жеңсе',
'жойыт',
'жуығы',
'жылды',
'жұлақ',
'жұрқа',
'жөтел',
'зарар',
'затқа',
'зақым',
'зияны',
'зікір',
'ибалы',
'иелік',
'имамы',
'кеміс',
'кенде',
'кепті',
'керме',
'кірме',
'кіріс',
'күрек',
'күтір',
'көбею',
'көзбе',
'көрім',
'лепес',
'марал',
'мақта',
'мұнар',
'назым',
'наным',
'нарық',
'нешеу',
'нобай',
'нәжіс',
'ойлау',
'ойсыз',
'орган',
'оспақ',
'отряд',
'оқтай',
'палау',
'парақ',
'радио',
'сабау',
'санат',
'себіл',
'судья',
'сызба',
'сынға',
'сүзгі',
'сүзек',
'сүрді',
'сүюге',
'сұрып',
'сәжде',
'сөгіс',
'тапшы',
'тақыт',
'тебен',
'топас',
'топта',
'тоқай',
'туфли',
'тысыр',
'түген',
'түзік',
'тұйпа',
'тұмау',
'тұтам',
'тәркі',
'тәтей',
'тәңір',
'шарғы',
'шейіт',
'шерлі',
'шыңға',
'шілік',
'экран',
'ірілі',
'ғұпыл',
'қазак',
'қатпа',
'қашыр',
'қойма',
'қойны',
'қолат',
'қорап',
'қорда',
'қылау',
'қырым',
'құлып',
'үйлес',
'үйрет',
'ұжмақ',
'ұлыма',
'ұтқыр',
'ұшқыш',
'әудем',
'өкпек',
'өлшеп',
'өрелі',
'өсиет',
'азайт',
'аздау',
'аздық',
'азғын',
'айшық',
'акыры',
'алаша',
'алуын',
'аптал',
'ардың',
'артуы',
'аршын',
'аспаз',
'астар',
'астас',
'асылы',
'атаны',
'аттық',
'аужал',
'ауысу',
'аштық',
'ағеке',
'ақбас',
'ақтық',
'ақшам',
'аңғар',
'балға',
'бақас',
'бақұл',
'бегім',
'бесті',
'бопса',
'бояма',
'боқта',
'білік',
'бұдыр',
'бұрау',
'бұтақ',
'бұғат',
'бөбек',
'вагон',
'вирус',
'грамм',
'даяшы',
'дағды',
'диван',
'дозақ',
'дәліз',
'елбең',
'елігу',
'ескек',
'еттік',
'жазам',
'жайыл',
'жалға',
'жанша',
'жапыр',
'жаршы',
'жауса',
'жебеп',
'жезде',
'жезді',
'желеу',
'желке',
'жидек',
'жоюға',
'жусан',
'жырақ',
'жырың',
'жықса',
'жұбан',
'жұлым',
'жұмық',
'жұпыр',
'жұтпа',
'жәдік',
'жәшік',
'завод',
'закон',
'зерде',
'иіске',
'калий',
'карап',
'кебек',
'кедір',
'кезбе',
'кекең',
'кемде',
'кемік',
'кенеу',
'кеніш',
'керіс',
'китің',
'күлік',
'күрзі',
'күрік',
'күріш',
'көрде',
'көріс',
'көшір',
'лағып',
'мазар',
'манар',
'масақ',
'маска',
'маңыз',
'мекер',
'метод',
'мойыл',
'мысты',
'мысын',
'мінбе',
'мінеп',
'мәләк',
'нарға',
'ныспы',
'нығыз',
'нәпіл',
'нәрлі',
'ождан',
'ойнау',
'ондық',
'перне',
'пилот',
'порын',
'пункт',
'пырақ',
'пішім',
'рамка',
'рауан',
'рухты',
'салым',
'самар',
'самға',
'санкт',
'саулы',
'сахна',
'сезік',
'сорап',
'сотты',
'судыр',
'сыйлы',
'сылақ',
'сюжет',
'сілем',
'сілті',
'сіңді',
'сүлік',
'сүрен',
'сүрең',
'сүрме',
'сұрша',
'сұғын',
'сәуір',
'сөзде',
'тайын',
'талау',
'талыс',
'талға',
'тауап',
'тауар',
'тақат',
'тақым',
'театр',
'терек',
'тетік',
'теңеу',
'тимес',
'тозды',
'томар',
'томды',
'топай',
'тумас',
'тыпың',
'тырма',
'түбір',
'түзей',
'түзеу',
'түлек',
'түлен',
'түмен',
'түпте',
'түскі',
'тұрғы',
'тұрқы',
'тәнті',
'төлем',
'уағыз',
'уақта',
'физик',
'фокус',
'хамса',
'шабыр',
'шалма',
'шалқи',
'шаншу',
'шапыр',
'шарық',
'шарқы',
'шатып',
'шаған',
'шекер',
'шемен',
'шойқы',
'шоқты',
'штрих',
'шылғи',
'шынды',
'шығай',
'шұрат',
'шөңге',
'ылғал',
'ырғақ',
'ыстан',
'ышқыр',
'ілуде',
'іркес',
'іңкәр',
'қадақ',
'қадім',
'қазба',
'қамар',
'қамау',
'қамқа',
'қарта',
'қасап',
'қасар',
'қағба',
'қағым',
'қисаң',
'қисын',
'қолқа',
'қорым',
'қорық',
'қосақ',
'қосын',
'қоқыс',
'қусын',
'қызың',
'қырқы',
'құзды',
'құлда',
'құпыл',
'құрау',
'үрдіс',
'үркек',
'үшкір',
'ұзына',
'ұйқас',
'ұсыну',
'ұшыра',
'әкелі',
'өкімі',
'өлтір',
'өнбес',
'өнген',
'өндір',
'өртеу',
'өтеуі',
'өңкей',
'абала',
'абжад',
'абхаз',
'аванс',
'адала',
'аджар',
'ажыра',
'азала',
'азаюы',
'азбар',
'аздыр',
'азсын',
'азула',
'азына',
'азған',
'азғыр',
'айбын',
'айдал',
'айдас',
'айдат',
'айдақ',
'айлан',
'айлап',
'айлас',
'айлақ',
'аймыш',
'айнам',
'айнаш',
'айныс',
'айныт',
'айпап',
'айсыз',
'айтат',
'айтақ',
'айтта',
'айтыл',
'айтық',
'айуан',
'айхай',
'айырт',
'айығу',
'айқар',
'айңай',
'акбар',
'актер',
'актив',
'акула',
'акциз',
'акция',
'алайт',
'алала',
'алалы',
'алапа',
'аларт',
'алару',
'албар',
'албау',
'албыр',
'албыт',
'алдас',
'алдат',
'алдыр',
'алеут',
'алжас',
'алмаз',
'алшай',
'алшаю',
'алшаң',
'алшит',
'алыбы',
'альфа',
'алғай',
'алғау',
'алғыз',
'алқам',
'алқын',
'амеба',
'аменш',
'ампер',
'амсна',
'анайы',
'аналы',
'анаша',
'ангар',
'ангор',
'андай',
'андат',
'анден',
'андыз',
'анжам',
'анчар',
'апала',
'апалы',
'апарт',
'апеке',
'апиын',
'апорт',
'апсит',
'аптат',
'аптау',
'апшыт',
'арала',
'арашы',
'арбал',
'арбас',
'арбат',
'арбау',
'арбақ',
'арбаң',
'арбыз',
'ардақ',
'армия',
'армян',
'арнал',
'арнас',
'арнат',
'арпаң',
'арпыл',
'арсаң',
'арсыл',
'арсын',
'артыл',
'артыс',
'арула',
'аршыл',
'аршыс',
'аршыт',
'арылт',
'арқын',
'асаба',
'асату',
'аския',
'аскөк',
'аспап',
'аспас',
'астау',
'астыр',
'асулы',
'асуру',
'асыну',
'асырт',
'асығу',
'асқыз',
'асқын',
'асқыш',
'атайы',
'атала',
'аталу',
'атасу',
'атбас',
'атеке',
'атжап',
'атком',
'атпаз',
'атпал',
'атсыз',
'аттам',
'аттат',
'аттау',
'аттыр',
'атылу',
'атысу',
'атқар',
'атқыз',
'атқыр',
'атқыт',
'аугер',
'аудар',
'аудаң',
'аудыр',
'аужай',
'аужар',
'ауком',
'аулан',
'аулас',
'аулат',
'аумақ',
'аунат',
'аунау',
'аусар',
'аухал',
'аухау',
'ауырт',
'ауыру',
'ауғыз',
'ауғын',
'ауғыш',
'ауқат',
'ауқау',
'ауқым',
'ашала',
'ашалы',
'аштыр',
'ашуат',
'ашылу',
'ашыма',
'ашына',
'ашынт',
'ашыну',
'ашыту',
'ашығу',
'ашқыз',
'ашқыл',
'ашқыш',
'ащыла',
'ащылы',
'аюоты',
'аязда',
'аязды',
'аялат',
'аялау',
'аялда',
'аярсу',
'аярсы',
'аяқсы',
'аяқшы',
'аяңда',
'ағала',
'ағарт',
'ағару',
'ағлөп',
'ағузы',
'ағызу',
'ағылт',
'ақаба',
'ақайт',
'ақгүл',
'ақжар',
'ақжем',
'ақзон',
'ақиыс',
'ақиық',
'ақсат',
'ақсаң',
'ақсит',
'ақтал',
'ақтан',
'ақтар',
'ақтас',
'ақтат',
'ақшит',
'ақшию',
'ақшөп',
'ақырт',
'ақыру',
'аққия',
'аңдат',
'аңдыс',
'аңдыт',
'аңлау',
'аңсат',
'аңсау',
'аңсақ',
'аңсыз',
'аңшыл',
'аңырт',
'аңқай',
'аңқаю',
'аңқит',
'аңқыт',
'бабай',
'бабау',
'багор',
'бадал',
'бадан',
'бажай',
'бажаң',
'бажби',
'бажыл',
'бажыр',
'базис',
'байла',
'байлы',
'байыр',
'байыс',
'байыт',
'балан',
'балар',
'балат',
'балау',
'балет',
'балпи',
'балшы',
'балқу',
'банды',
'банка',
'банкі',
'бапан',
'бапта',
'бапшы',
'баран',
'барби',
'баржи',
'барла',
'барлы',
'баршы',
'барыл',
'басат',
'басма',
'басыл',
'басыр',
'басыс',
'басқы',
'батби',
'батти',
'батым',
'батқы',
'баула',
'баулу',
'баура',
'баушы',
'башай',
'башақ',
'бағжи',
'бағыл',
'бағыс',
'бағыш',
'бақай',
'бақал',
'бақар',
'бақши',
'бақыл',
'бедең',
'бежін',
'безді',
'безек',
'безел',
'безен',
'безеу',
'безін',
'бейіш',
'бекас',
'бекіл',
'бекім',
'бекін',
'бекіс',
'бекіт',
'белде',
'белең',
'белше',
'бенде',
'беред',
'берет',
'берку',
'беркі',
'беріл',
'берім',
'беріш',
'бесеу',
'беткі',
'бетон',
'бетше',
'биден',
'бизон',
'билен',
'бином',
'бипаз',
'биржа',
'бисін',
'битте',
'битті',
'биғам',
'бланк',
'бодам',
'бодан',
'бодау',
'божби',
'бозар',
'бозда',
'бозот',
'бозша',
'бозым',
'бойла',
'бойра',
'болжа',
'болпи',
'болым',
'бомай',
'бомап',
'бомба',
'бопыр',
'борат',
'борау',
'борби',
'боржи',
'боржу',
'боржы',
'борла',
'борлы',
'борпы',
'борси',
'борсу',
'борсы',
'борти',
'босан',
'босау',
'боспа',
'босыт',
'ботқа',
'бошақ',
'боған',
'боқат',
'боқша',
'бронх',
'бронь',
'бсанк',
'бсесп',
'бснде',
'буазу',
'буазы',
'будан',
'будыр',
'булан',
'булат',
'булау',
'бунал',
'бунау',
'бунақ',
'бурят',
'бусан',
'буулы',
'буыну',
'буғыз',
'быжыл',
'быжыр',
'былпи',
'былши',
'былық',
'былға',
'былқу',
'былқы',
'быржи',
'бырси',
'бырти',
'быршу',
'быршы',
'бырыс',
'бытти',
'бытыр',
'бытыс',
'бықсу',
'бықсы',
'бықыт',
'бізди',
'бізше',
'білен',
'білеу',
'біллә',
'білін',
'біліс',
'бірле',
'бірлә',
'бірме',
'бісте',
'бістә',
'бітел',
'бітес',
'бітет',
'бітию',
'бітік',
'бітір',
'бітіс',
'бүгжи',
'бүгіл',
'бүгіс',
'бүдан',
'бүйен',
'бүкең',
'бүкпе',
'бүксу',
'бүксі',
'бүкте',
'бүкши',
'бүкіш',
'бүлан',
'бүлде',
'бүлін',
'бүнда',
'бүрге',
'бүрке',
'бүрку',
'бүрле',
'бүрлі',
'бүрме',
'бүрси',
'бүрше',
'бүріл',
'бүріс',
'бұзыл',
'бұзыс',
'бұйым',
'бұйыр',
'бұлан',
'бұлау',
'бұлда',
'бұлды',
'бұлти',
'бұлың',
'бұлға',
'бұлқу',
'бұлқы',
'бұнша',
'бұрал',
'бұрат',
'бұрма',
'бұрти',
'бұрым',
'бұрғы',
'бұрқа',
'бұтал',
'бұтау',
'бұтып',
'бұтыр',
'бұғаз',
'бұғақ',
'бұғыз',
'бұқпа',
'бәдел',
'бәден',
'бәйіт',
'бәкін',
'бәлек',
'бәлен',
'бәлсу',
'бәлсі',
'бәлту',
'бәліш',
'бәрбі',
'бәрпі',
'бәрте',
'бәсей',
'бәсең',
'бәтуа',
'бәтір',
'бәшек',
'бәшке',
'бәңгі',
'бәңші',
'бөгел',
'бөген',
'бөгес',
'бөгеу',
'бөдес',
'бөжек',
'бөкес',
'бөкпе',
'бөкіс',
'бөлен',
'бөлер',
'бөлес',
'бөлет',
'бөлеу',
'бөлке',
'бөлін',
'бөпеш',
'бөрпе',
'бөрту',
'бөсір',
'бөшке',
'валик',
'вальс',
'ванна',
'вахта',
'велюр',
'венгр',
'верфь',
'взаим',
'взвод',
'визит',
'вилла',
'вольт',
'вотум',
'газда',
'газел',
'газшы',
'гайка',
'галош',
'гамак',
'гамма',
'гарем',
'гарус',
'гелий',
'герге',
'гетто',
'гитар',
'гобой',
'говор',
'гольф',
'грант',
'графа',
'грипп',
'грифі',
'гуашь',
'гудок',
'гулес',
'гулет',
'гулеу',
'гусар',
'гілес',
'гүлде',
'гүрзі',
'гүріл',
'гұмыр',
'гөзел',
'гөйле',
'дабыс',
'дадан',
'дайра',
'дайым',
'далан',
'далап',
'далау',
'далаң',
'далба',
'далда',
'далди',
'далит',
'далию',
'дамыт',
'дарби',
'дарди',
'даршы',
'дарыл',
'дарыт',
'датта',
'дауал',
'даула',
'даушы',
'дағар',
'дағуа',
'даңды',
'дебат',
'дебет',
'дебют',
'дебіл',
'дегду',
'дегді',
'дегіз',
'дедек',
'декан',
'делбе',
'делди',
'делін',
'дембе',
'демес',
'демос',
'денде',
'деней',
'десбі',
'десте',
'десун',
'дефис',
'дзюдо',
'дигәй',
'дидар',
'диета',
'дилан',
'димек',
'динар',
'добал',
'добыр',
'догма',
'дожна',
'дойыр',
'докер',
'долаң',
'долыр',
'домда',
'домна',
'домшы',
'донор',
'дорби',
'дорди',
'доғал',
'доғар',
'драма',
'дрель',
'дуала',
'дуана',
'дуашы',
'дулас',
'дулау',
'дуэль',
'дыбыр',
'дызыл',
'дымда',
'дымды',
'дырау',
'дырду',
'дыруы',
'дырыл',
'дығыр',
'дықты',
'дыңыл',
'дыңғы',
'дігер',
'дікек',
'дікің',
'ділда',
'дітте',
'діңке',
'діңки',
'дүбіл',
'дүйім',
'дүкер',
'дүмбе',
'дүмбі',
'дүмді',
'дүмел',
'дүмет',
'дүмпи',
'дүмпу',
'дүмпі',
'дүмше',
'дүрбі',
'дүрди',
'дүріл',
'дүңки',
'дүңку',
'дүңкі',
'дұлаң',
'дұлба',
'дұғай',
'дәкең',
'дәлде',
'дәлди',
'дәлду',
'дәмде',
'дәмет',
'дәнде',
'дәнек',
'дәнен',
'дәріп',
'дәспі',
'дәсте',
'дәуем',
'дәусу',
'дәусі',
'дәуіт',
'дөдең',
'дөкей',
'дөкет',
'дөкір',
'дөкөң',
'дөпте',
'дөңді',
'дөңки',
'ебдей',
'ебене',
'еврей',
'егдей',
'егдем',
'егдер',
'егелу',
'егесу',
'егуші',
'ежеле',
'ежіре',
'езгіз',
'езгіш',
'ездір',
'езсін',
'езуле',
'езуші',
'езілу',
'еккіз',
'екпін',
'ексім',
'ексіт',
'ектір',
'екшел',
'екшеп',
'екшет',
'елбек',
'елдес',
'елеул',
'елжір',
'елпек',
'елпең',
'елпіл',
'елтең',
'елтіт',
'елшіл',
'еліле',
'елірт',
'емгіз',
'емдел',
'емдет',
'емдәм',
'емеру',
'емест',
'емеше',
'емпей',
'емпең',
'емсек',
'емсеу',
'емсіз',
'емсін',
'емуші',
'емізу',
'емінт',
'еміну',
'енбау',
'енгіз',
'ендей',
'ендес',
'ендеу',
'ендір',
'енеке',
'епсек',
'епсіз',
'епсін',
'ептес',
'ептет',
'ептеу',
'ептеш',
'епшіл',
'ербек',
'ербит',
'ербию',
'ергіз',
'ергіш',
'ересе',
'еркеш',
'ерлен',
'ерлеу',
'ермен',
'ермең',
'ернеу',
'ерсек',
'ерсіз',
'ерсіл',
'ертек',
'ертте',
'еруле',
'ерулі',
'ерігу',
'есейт',
'еселе',
'еселі',
'ескер',
'ескіз',
'ескір',
'ескіш',
'естай',
'естек',
'естіл',
'есілт',
'есірт',
'есіру',
'етейт',
'етжең',
'еткел',
'еткіз',
'етсіз',
'еттен',
'еттір',
'етшең',
'етқен',
'етқор',
'еңеле',
'еңкей',
'еңкек',
'еңкес',
'еңкеу',
'еңкею',
'еңкіс',
'еңкіш',
'еңлік',
'еңсей',
'еңсер',
'еңіре',
'жабыл',
'жабын',
'жадан',
'жазаң',
'жазыл',
'жазыс',
'жайла',
'жайна',
'жайпа',
'жайшы',
'жайыс',
'жайға',
'жайқа',
'жаксы',
'жакып',
'жалам',
'жалан',
'жалас',
'жалат',
'жалақ',
'жалпи',
'жалқу',
'жамал',
'жамап',
'жамас',
'жамау',
'жампи',
'жамса',
'жамшы',
'жамыл',
'жанан',
'жанап',
'жанас',
'жанақ',
'жанры',
'жанту',
'жаншу',
'жаншы',
'жаныл',
'жаныш',
'жапақ',
'жапон',
'жаппа',
'жарал',
'жарас',
'жарат',
'жарби',
'жарла',
'жарма',
'жарна',
'жарыл',
'жарыс',
'жарыт',
'жарқы',
'жасал',
'жасан',
'жасас',
'жасат',
'жасаң',
'жасыт',
'жатаң',
'жатта',
'жатыс',
'жаула',
'жаулы',
'жаура',
'жауша',
'жаушы',
'жауыр',
'жаюлы',
'жағыз',
'жағыл',
'жағым',
'жағыс',
'жақша',
'жаңан',
'жаңар',
'жаңат',
'жаңса',
'жаңыл',
'жаһит',
'жебей',
'жебеу',
'жебір',
'жегіз',
'жегіл',
'жегін',
'жедді',
'жейде',
'жекей',
'жекен',
'жекет',
'жеккі',
'жекір',
'желде',
'желен',
'желет',
'желпу',
'желін',
'жемде',
'жемір',
'жемің',
'жерле',
'жеріт',
'жетек',
'жетеу',
'жетіл',
'жетіс',
'жидыр',
'жидіт',
'жинал',
'жинас',
'жинат',
'жинақ',
'жисаң',
'жиыла',
'жиырт',
'жиыру',
'жиюлы',
'жиіле',
'жиғыз',
'жиңаз',
'жиңан',
'жойыл',
'жойыс',
'жолан',
'жолат',
'жолма',
'жолшы',
'жонды',
'жоныл',
'жоныс',
'жонғы',
'жорту',
'жорыл',
'жорыт',
'жосыл',
'жосын',
'жосыт',
'жоғал',
'жоқшы',
'жоңқа',
'жуалы',
'жуасу',
'жуасы',
'жудыр',
'жусат',
'жусау',
'жуыту',
'жуғыз',
'жуғыш',
'жылат',
'жылақ',
'жылжу',
'жылжы',
'жылма',
'жылми',
'жылти',
'жылуы',
'жылыс',
'жылыт',
'жымба',
'жымда',
'жымит',
'жымию',
'жымпи',
'жымси',
'жымыр',
'жырби',
'жырла',
'жырма',
'жырта',
'жырти',
'жырту',
'жырыл',
'жытыр',
'жығыл',
'жығыс',
'жібіт',
'жікте',
'жіпсі',
'жіпше',
'жітім',
'жұмыс',
'жүдет',
'жүдең',
'жүзбе',
'жүзіс',
'жүзіт',
'жүйек',
'жүйел',
'жүйес',
'жүйме',
'жүкте',
'жүкші',
'жүлге',
'жүмлә',
'жүнде',
'жүнжі',
'жүрле',
'жүрші',
'жүріл',
'жүрім',
'жұбай',
'жұбат',
'жұбау',
'жұбыр',
'жүзім',
'жұйпа',
'жұлаң',
'жұлыс',
'жұлқу',
'жұлқы',
'жұмыл',
'жұппа',
'жұпта',
'жұрат',
'жұрым',
'жұрын',
'жұтан',
'жұтат',
'жұтау',
'жұтаң',
'жұтыл',
'жұтын',
'жұғым',
'жұғыс',
'жұқар',
'жұқта',
'жәдит',
'жәйің',
'жәлем',
'жәлеп',
'жөлке',
'жөнде',
'жөнеу',
'жөрге',
'жөрме',
'жөңку',
'жөңкі',
'забар',
'забой',
'забын',
'забыт',
'загон',
'зайып',
'зайыр',
'заказ',
'залды',
'замат',
'замыр',
'запас',
'запон',
'зарла',
'зарпы',
'заряд',
'затон',
'заула',
'зауыт',
'зачет',
'зағип',
'зақпы',
'заңар',
'заңшы',
'звено',
'зейіл',
'зейін',
'зеккі',
'зекір',
'зенит',
'зерек',
'зерен',
'зерең',
'зерле',
'зомбы',
'зорай',
'зорат',
'зораю',
'зораң',
'зорла',
'зуала',
'зулат',
'зулау',
'зыбан',
'зымға',
'зырла',
'зыррқ',
'зырыл',
'зырғу',
'зырғы',
'зытти',
'зытыр',
'зығыр',
'зықым',
'зыңыл',
'зікін',
'зәбин',
'зәкте',
'зәкір',
'зәрде',
'зәтте',
'зәуде',
'зәуір',
'зәңгі',
'зәңки',
'игерт',
'игілі',
'идара',
'иегер',
'иекте',
'иекті',
'иелен',
'иемде',
'изелт',
'илант',
'илану',
'илену',
'илхам',
'илігу',
'имену',
'инелі',
'исіну',
'итаяқ',
'итепе',
'итерт',
'итеру',
'итжуа',
'итпек',
'иттес',
'иттік',
'иықта',
'иықты',
'иілім',
'иіліс',
'иінде',
'иінді',
'иіріл',
'иіріс',
'иіссу',
'иіңкі',
'какао',
'калош',
'калып',
'камин',
'канал',
'канат',
'капот',
'карат',
'каска',
'касса',
'каста',
'катар',
'катод',
'каток',
'кафес',
'каюта',
'каһир',
'кварц',
'кебен',
'кебеп',
'кебер',
'кебір',
'кегей',
'кегер',
'кеден',
'кежек',
'кежеу',
'кежім',
'кежір',
'кезел',
'кезен',
'кезер',
'кезет',
'кезеу',
'кейки',
'кейкі',
'кейіт',
'кекеп',
'кекес',
'кекет',
'кекеу',
'кекеш',
'кекжи',
'кекит',
'кекию',
'кекте',
'кекши',
'кекіл',
'кекір',
'келде',
'келек',
'келеп',
'келти',
'келім',
'кемді',
'кемер',
'кемеш',
'кемси',
'кемір',
'кеміт',
'кеней',
'кенел',
'кенен',
'кенер',
'кенші',
'кепеш',
'кеппе',
'кепсу',
'кепсі',
'кепте',
'кергу',
'кергі',
'керди',
'керне',
'керсу',
'керсі',
'керте',
'керти',
'керту',
'керіз',
'керік',
'керіл',
'керім',
'керіш',
'кескі',
'кеспе',
'кесіл',
'кесіс',
'кетек',
'кетеу',
'кетит',
'кетіл',
'кетім',
'кетір',
'кетіс',
'кеуек',
'кеуел',
'кеузе',
'кеуле',
'кеуіл',
'кеуім',
'кеуіш',
'кешел',
'кешен',
'кешең',
'кешіл',
'кеңей',
'кеңею',
'кеңки',
'кеңіс',
'кеңіт',
'кидір',
'киоск',
'кипер',
'кирең',
'кирәк',
'киіну',
'клише',
'клоун',
'кобра',
'кодта',
'кодын',
'койды',
'койка',
'койот',
'кокос',
'конус',
'корей',
'крест',
'кроос',
'ктерд',
'кубик',
'кубок',
'кубте',
'кузов',
'кулак',
'курып',
'кухня',
'куәлы',
'кіган',
'кідір',
'кідің',
'кіжін',
'кіжің',
'кілби',
'кілет',
'кілкі',
'кілми',
'кілти',
'кінәз',
'кірби',
'кіреу',
'кіржи',
'кірле',
'кірлі',
'кірне',
'кірти',
'кіріш',
'кітір',
'кіште',
'кішір',
'күбін',
'күбіт',
'күбің',
'күдір',
'күдіс',
'күжіл',
'күжір',
'күзек',
'күзел',
'күзем',
'күзес',
'күзеу',
'күйез',
'күйек',
'күйле',
'күйре',
'күйсі',
'күйші',
'күйіс',
'күлде',
'күлер',
'күлес',
'күлте',
'күлше',
'күліс',
'күмпи',
'күнбе',
'күнес',
'күнсу',
'күнсі',
'күнту',
'күпек',
'күпит',
'күпию',
'күпсу',
'күпсі',
'күпте',
'күпіл',
'күпін',
'күрби',
'күрде',
'күрез',
'күрел',
'күреу',
'күржи',
'күржі',
'күрле',
'күрме',
'күрсі',
'күрте',
'күрті',
'күріл',
'күсет',
'күтет',
'күтіл',
'күтім',
'күтін',
'күтіс',
'күшей',
'күшен',
'күште',
'кәдір',
'кәжең',
'кәзір',
'кәлам',
'кәллә',
'кәләм',
'кәмек',
'кәріз',
'кәріп',
'кәріс',
'кәрің',
'кәсек',
'кәтеп',
'кәтте',
'кәуап',
'кәуки',
'кәуір',
'кәшек',
'көбей',
'көбең',
'көгер',
'көдек',
'көзде',
'көзел',
'көзше',
'көзір',
'көйле',
'көйіт',
'көкей',
'көкет',
'көкеш',
'көксе',
'көкши',
'көкіт',
'көлбе',
'көлбі',
'көлем',
'көлең',
'көлкі',
'көлпі',
'көмбе',
'көмег',
'көмей',
'көмпи',
'көмпу',
'көміл',
'көмір',
'көміс',
'көнек',
'көнер',
'көнти',
'көнші',
'көпей',
'көпек',
'көпес',
'көппе',
'көпсу',
'көпсі',
'көпше',
'көріл',
'көсек',
'көсел',
'көсет',
'көсеу',
'көскі',
'көсте',
'көсти',
'көсіл',
'көсіт',
'көтен',
'көтер',
'көтеу',
'көшен',
'көшет',
'көшкі',
'лабаз',
'лабақ',
'ладья',
'лажда',
'лазер',
'лазым',
'лайка',
'лайла',
'лайым',
'лакта',
'лампы',
'ламут',
'лапас',
'лапса',
'лапыз',
'ларын',
'ласта',
'латын',
'латыш',
'лаушы',
'лафет',
'лахат',
'лаған',
'лағын',
'лақат',
'лақпа',
'лақса',
'лақта',
'лақты',
'лаққа',
'лаққы',
'лаңда',
'лаңшы',
'лденд',
'левит',
'лейка',
'лекеш',
'лемма',
'ленед',
'ленин',
'лента',
'лепті',
'леуші',
'лиана',
'ликер',
'лимит',
'лимон',
'лимфа',
'линза',
'липси',
'лирик',
'литва',
'литий',
'лицей',
'лобия',
'лоблы',
'лотос',
'лоция',
'лоқсу',
'лоқсы',
'лоққы',
'лоңқа',
'лыпыл',
'лыпың',
'лықит',
'лықсы',
'лықыл',
'лүпіл',
'лұғат',
'ләгөн',
'ләйлі',
'ләңгі',
'ләңке',
'магия',
'магма',
'мажал',
'мажор',
'мазда',
'мазут',
'майек',
'майка',
'майла',
'майми',
'майшы',
'майып',
'майыр',
'майыс',
'майқы',
'макет',
'малту',
'малты',
'малшу',
'маман',
'манат',
'манго',
'манду',
'манды',
'манеж',
'манет',
'мания',
'манто',
'манты',
'марго',
'марды',
'маржа',
'марка',
'марту',
'марғу',
'марқа',
'масай',
'масат',
'масаю',
'масаң',
'масса',
'масша',
'матал',
'матас',
'матат',
'матау',
'матыр',
'мауын',
'мауқы',
'маяла',
'маялы',
'маяшы',
'мағаз',
'мақар',
'мақау',
'маққа',
'маңқи',
'мдерд',
'мдері',
'мегде',
'мегзе',
'медик',
'мезге',
'мейіл',
'мелже',
'мелше',
'мелши',
'менше',
'мерез',
'месел',
'месін',
'метан',
'метей',
'метил',
'метро',
'мешел',
'мешеу',
'мещан',
'меңде',
'меңді',
'меңзе',
'меңзі',
'меңше',
'мизам',
'мизан',
'милас',
'милау',
'минер',
'миома',
'мипаз',
'мипая',
'мисал',
'мисыз',
'митың',
'мияла',
'мияқы',
'миқит',
'миқию',
'мойша',
'мойыт',
'молай',
'молаю',
'молақ',
'монах',
'монақ',
'монти',
'монша',
'морзе',
'морла',
'морпа',
'моряк',
'мотив',
'мотор',
'мошқа',
'моғал',
'моқал',
'муалы',
'музей',
'мулат',
'муляж',
'мумия',
'муфта',
'муфти',
'мыжыл',
'мыжыт',
'мыжың',
'мыжғы',
'мызғу',
'мызғы',
'мынан',
'мыржи',
'мырси',
'мырти',
'мырыш',
'мытыл',
'мытым',
'мытқу',
'мықит',
'мықию',
'мықта',
'мықши',
'мықын',
'мықыр',
'мыңғы',
'мігір',
'мінгі',
'мінде',
'мінел',
'мінес',
'мінеу',
'мінші',
'мініл',
'мінін',
'мініс',
'мүдір',
'мүжіл',
'мүжіт',
'мүйет',
'мүйіс',
'мүкті',
'мүкіс',
'мүлгу',
'мүлгі',
'мүләй',
'мүләю',
'мүпти',
'мүрде',
'мүсей',
'мүсет',
'мүшел',
'мүңку',
'мүңкі',
'мүңсу',
'мүңсі',
'мұжыл',
'мұжық',
'мұзда',
'мұзды',
'мұрап',
'мұржа',
'мұрса',
'мұсай',
'мұшақ',
'мұшта',
'мұқал',
'мұқам',
'мұқат',
'мұқау',
'мұқыл',
'мұқым',
'мұқыр',
'мұңай',
'мұңаю',
'мәдет',
'мәжер',
'мәжін',
'мәйек',
'мәйім',
'мәйіт',
'мәкір',
'мәлік',
'мәлін',
'мәнге',
'мәнде',
'мәнет',
'мәнті',
'мәніс',
'мәпит',
'мәтек',
'мәтел',
'мәтке',
'мәути',
'мәуіт',
'мәңгу',
'мөрле',
'мөрлі',
'мөтен',
'мөшке',
'мөңке',
'мөңку',
'мөңкі',
'набат',
'набыт',
'навай',
'наган',
'назды',
'налим',
'налыс',
'нанай',
'нанар',
'нанке',
'наншы',
'нарау',
'нарлы',
'нарша',
'наряд',
'насар',
'насат',
'насос',
'настя',
'насыр',
'науат',
'наулы',
'науры',
'науша',
'науғы',
'наяты',
'нақақ',
'нақпа',
'нақыл',
'нақыс',
'нақыш',
'ндетт',
'некен',
'некес',
'нерпа',
'несеп',
'несие',
'несіп',
'нетто',
'нешік',
'низам',
'нкция',
'норка',
'норма',
'нулан',
'нымша',
'нысай',
'нығай',
'нықта',
'нықыр',
'нықыт',
'нюанс',
'нілде',
'нұқан',
'нұқыл',
'нәжес',
'нәзір',
'нәйет',
'нәкес',
'нәлде',
'нәлді',
'нәнсі',
'нәрле',
'нәсіл',
'нәсіп',
'нәште',
'нәшті',
'нөсер',
'оазис',
'образ',
'оброк',
'обылу',
'обығу',
'одеял',
'одыра',
'озады',
'оздыр',
'озғын',
'ойдыр',
'ойлас',
'ойлат',
'оймыш',
'ойнал',
'ойнат',
'ойпыл',
'ойпыр',
'ойтаң',
'ойшаң',
'ойшыл',
'ойысу',
'ойғыз',
'ойғыш',
'ойқой',
'ойқыл',
'октет',
'олеум',
'омалу',
'омақа',
'омега',
'омела',
'омлет',
'омпақ',
'омырт',
'омыру',
'омығу',
'ондап',
'оникс',
'опала',
'опера',
'опись',
'оптик',
'опыну',
'опырт',
'опыру',
'опығу',
'оралт',
'орама',
'орант',
'ордаң',
'орден',
'ордер',
'орлан',
'орлас',
'орлат',
'орлау',
'орнат',
'орнау',
'ортай',
'ортаю',
'ортқу',
'ортқы',
'орулы',
'орушы',
'оршыт',
'орғыз',
'орғыл',
'орғыт',
'орғыш',
'осмий',
'остыр',
'осылт',
'осылу',
'осқрт',
'осқыр',
'отайт',
'оташы',
'отсыз',
'оттас',
'оттат',
'оттау',
'оттық',
'отшаң',
'отшыл',
'отырт',
'отығу',
'очерк',
'оюлас',
'оюлат',
'оюлау',
'оязда',
'ояныс',
'ояста',
'оясыз',
'ояңда',
'оқала',
'оқашы',
'оқпан',
'оқпақ',
'оқсат',
'оқтал',
'оқтан',
'оқтас',
'оқтат',
'оқтау',
'оқулы',
'оқшит',
'оқшию',
'оқыра',
'оқысу',
'оңалт',
'оңалу',
'оңбау',
'оңдал',
'оңдас',
'оңдат',
'оңдау',
'оңдыр',
'оңсын',
'оңтай',
'оңшыл',
'оңысу',
'оңғар',
'оңғақ',
'оңғыш',
'оңқай',
'пазыл',
'пайза',
'паймы',
'палас',
'пампа',
'панар',
'панер',
'панты',
'папье',
'парад',
'парик',
'парта',
'паста',
'пасха',
'пасық',
'патыр',
'пафос',
'пашақ',
'пашке',
'паңсу',
'паңсы',
'пенал',
'петит',
'пешші',
'пикет',
'пират',
'пирог',
'пияла',
'плита',
'плугі',
'погон',
'поезд',
'полип',
'полис',
'полка',
'полюс',
'поляр',
'понуд',
'поэма',
'пресс',
'прима',
'принц',
'проба',
'проза',
'психо',
'пульт',
'пырла',
'пырыл',
'пысыл',
'пышта',
'пышту',
'пышыл',
'пьеса',
'підия',
'пісір',
'пітір',
'пішпе',
'пүасы',
'пүліш',
'пәйек',
'пәлек',
'пәрша',
'рабай',
'раббы',
'радар',
'радий',
'разон',
'райлы',
'райыс',
'рамат',
'рампа',
'раста',
'рауаш',
'раунд',
'рахит',
'рация',
'ребус',
'регби',
'редут',
'рейка',
'рельс',
'ренжі',
'рента',
'репер',
'репет',
'ресім',
'ретті',
'рибес',
'ризық',
'рикша',
'римде',
'рифма',
'рияла',
'риялы',
'ркелд',
'рленд',
'рлену',
'робот',
'ролик',
'рондо',
'ротар',
'рояль',
'рубаи',
'рубин',
'рулас',
'рулет',
'рулон',
'румба',
'румын',
'рупия',
'рупор',
'русыз',
'рушыл',
'рынок',
'рының',
'рычаг',
'рюмка',
'рәпат',
'рәсім',
'сабаз',
'сабал',
'сабас',
'сабат',
'сабыл',
'сабыт',
'сазай',
'сазар',
'сазда',
'сазын',
'сайла',
'сайлы',
'сайма',
'сайра',
'сайыз',
'сайыл',
'салақ',
'салаң',
'салли',
'салпи',
'салта',
'салын',
'салыс',
'салқа',
'салқы',
'саман',
'самса',
'самыр',
'санал',
'санас',
'санақ',
'сапан',
'сапақ',
'сапер',
'сапси',
'сапсу',
'сапсы',
'сапыр',
'сарап',
'саржа',
'сарна',
'сарсу',
'сарсы',
'сарша',
'сарыл',
'сарыт',
'сарқу',
'сарқы',
'сасай',
'сасыт',
'сатал',
'сатан',
'сатыл',
'сатын',
'сатыс',
'сауат',
'саула',
'сауса',
'сауыл',
'сауыр',
'сауыс',
'сахар',
'саяды',
'саяла',
'саялы',
'сағал',
'сағақ',
'сағыз',
'сағын',
'сағың',
'сақай',
'сақар',
'сақаю',
'сақию',
'сақси',
'сақыр',
'саңғу',
'саңғы',
'саңқи',
'саңқу',
'саһиб',
'себеб',
'себей',
'себет',
'себін',
'себіс',
'север',
'сезіл',
'сезін',
'сезіс',
'сейіс',
'сейіт',
'секек',
'секер',
'секеу',
'секең',
'секию',
'секси',
'секір',
'селбе',
'селбі',
'селде',
'селен',
'селеу',
'селең',
'селти',
'семья',
'семір',
'сенбі',
'сенек',
'сеніс',
'сеппе',
'сепсу',
'сепсі',
'септе',
'серби',
'сергу',
'сергі',
'серей',
'серек',
'серею',
'серия',
'серме',
'серне',
'серпу',
'серпі',
'сесті',
'сетіл',
'сидам',
'сидит',
'сидию',
'сипан',
'сипас',
'сипау',
'сипақ',
'сират',
'сирет',
'сиреу',
'сироп',
'сиыну',
'сиыру',
'сияла',
'сиялы',
'сиқаң',
'смета',
'смола',
'собор',
'созаң',
'созыл',
'сойди',
'сойлы',
'сойым',
'сойыс',
'сойқы',
'солақ',
'солаң',
'солма',
'солпи',
'солпы',
'сомда',
'сомпа',
'сопай',
'сопаю',
'сопаң',
'сопит',
'сопию',
'сорай',
'сораю',
'сораң',
'сорла',
'сорыл',
'сорғы',
'сости',
'соғыл',
'соғым',
'соғын',
'соқаң',
'соқпа',
'соқта',
'соқым',
'спейд',
'спейт',
'спеуш',
'спирт',
'старт',
'стейд',
'стейт',
'стенд',
'стилі',
'суаит',
'суалт',
'суалу',
'суарт',
'суару',
'сулан',
'сулат',
'сулау',
'суоты',
'сусат',
'сусау',
'сусек',
'сусыл',
'сусыт',
'сутас',
'сушыл',
'суыну',
'суырт',
'суыру',
'суысу',
'суыту',
'суғар',
'суқан',
'суқит',
'съезд',
'сыбай',
'сыбат',
'сыбау',
'сыбыр',
'сыдыр',
'сызда',
'сызды',
'сызыл',
'сыйла',
'сыйыс',
'сылан',
'сылас',
'сылат',
'сылау',
'сылбу',
'сылбы',
'сылым',
'сылын',
'сылыс',
'сылыт',
'сылқи',
'сымау',
'сымақ',
'сымпи',
'сынал',
'сынар',
'сынас',
'сынат',
'сынақ',
'сының',
'сыпта',
'сыпти',
'сыпыр',
'сырла',
'сырма',
'сыршы',
'сырыл',
'сырғу',
'сырғы',
'сырқа',
'сытыл',
'сытыр',
'сығай',
'сығыл',
'сығым',
'сығын',
'сығыс',
'сықал',
'сықас',
'сықат',
'сықау',
'сықпа',
'сықси',
'сықта',
'сыңсу',
'сыңсы',
'сюита',
'сілбі',
'сілей',
'сілет',
'сілеу',
'сілею',
'сілку',
'сілкі',
'сілте',
'сілім',
'сімір',
'сірге',
'сірес',
'сіреу',
'сірне',
'сіңлі',
'сіңіс',
'сүген',
'сүдін',
'сүзбе',
'сүзіл',
'сүзіс',
'сүйде',
'сүйел',
'сүйен',
'сүйес',
'сүйет',
'сүйеу',
'сүйке',
'сүйре',
'сүйін',
'сүйіс',
'сүлгі',
'сүлде',
'сүлей',
'сүмбі',
'сүмек',
'сүмең',
'сүмпи',
'сүнгі',
'сүргі',
'сүрей',
'сүрек',
'сүрле',
'сүрін',
'сүтей',
'сүтею',
'сүтті',
'сүтші',
'сүңгу',
'сүңгі',
'сұйыл',
'сұлат',
'сұлау',
'сұлду',
'сұрал',
'сұран',
'сұрат',
'сұсти',
'сұғыл',
'сұғыс',
'сұқаң',
'сұқит',
'сұқпа',
'сұқси',
'сұқта',
'сұқыр',
'сұңқи',
'сәбіз',
'сәмбі',
'сәмек',
'сәнде',
'сәтен',
'сәңки',
'сөгет',
'сөгіл',
'сөзбе',
'сөйту',
'сөкте',
'сөлді',
'сөлпи',
'сөмке',
'сөмпи',
'сөтке',
'сөтіл',
'сөңке',
'табло',
'табор',
'табыл',
'табыр',
'табыт',
'тазар',
'тайга',
'тайла',
'тайпи',
'тайша',
'тайшы',
'тайыз',
'тайқа',
'тайқи',
'тайқу',
'тайқы',
'такса',
'такси',
'талар',
'талат',
'талда',
'талер',
'талон',
'талпи',
'талти',
'талша',
'тамса',
'танап',
'танго',
'танин',
'танту',
'танты',
'таныл',
'таныт',
'тапат',
'тапер',
'тапир',
'тапта',
'тапыл',
'тапыр',
'тарал',
'таран',
'тарас',
'тараю',
'тарақ',
'тараң',
'тарби',
'тарды',
'тариф',
'тарна',
'тарпу',
'тарпы',
'тарса',
'тарыл',
'тарқа',
'тасшы',
'тасыл',
'тасыс',
'тасыт',
'татау',
'татым',
'татыр',
'татыс',
'татыт',
'тауан',
'тауыс',
'тағыл',
'тағым',
'тақал',
'тақас',
'тақақ',
'тақсы',
'тақуа',
'тақша',
'тақыл',
'тақыс',
'таңыл',
'таңыс',
'таңқи',
'таңқы',
'тебіз',
'тебім',
'тебін',
'тебіс',
'тегеш',
'тежел',
'тежес',
'тежет',
'тезбе',
'тезде',
'тезис',
'текпі',
'телми',
'тембр',
'темек',
'тенор',
'тепек',
'тепең',
'тепкі',
'тепшу',
'тепші',
'тербе',
'терле',
'терме',
'терте',
'тершу',
'теріл',
'теспе',
'тесіл',
'теңбе',
'теңде',
'теңел',
'теңес',
'теңет',
'теңки',
'тигіз',
'тидім',
'тидір',
'типті',
'тираж',
'тиран',
'титан',
'титул',
'тияды',
'ткань',
'товар',
'тозда',
'тойла',
'тойшы',
'тойым',
'толым',
'толыс',
'толға',
'толқы',
'томат',
'томда',
'томпи',
'томыр',
'тонал',
'тонас',
'тонат',
'тонна',
'тонус',
'топаз',
'топан',
'топса',
'топшы',
'топыл',
'топын',
'торай',
'торал',
'торба',
'торий',
'торла',
'торос',
'торси',
'торта',
'торти',
'торыл',
'торыс',
'торыт',
'тосап',
'тосаң',
'тоспа',
'тосыл',
'тосыс',
'тотай',
'тотем',
'тотит',
'тотию',
'тотты',
'тоғар',
'тоғас',
'тоғаю',
'тоғыл',
'тоғын',
'тоғыс',
'тоғыт',
'тоқаш',
'тоқыл',
'тоқыс',
'тоқыт',
'тоңда',
'тоңқу',
'тоңқы',
'тракт',
'транс',
'трест',
'триас',
'триер',
'трико',
'тромб',
'труба',
'трусы',
'трюмо',
'туайт',
'туасы',
'тудыр',
'тулан',
'тулат',
'тулау',
'тулақ',
'тулий',
'тумба',
'тумыш',
'турал',
'турам',
'турас',
'турат',
'турау',
'турба',
'турин',
'турне',
'тушар',
'туылу',
'туысу',
'туғыз',
'туғыш',
'тыбыр',
'тыжыр',
'тыйыл',
'тыйын',
'тылти',
'тымау',
'тымпи',
'тымыр',
'тыншу',
'тыншы',
'тырай',
'тыран',
'тыраш',
'тыраю',
'тыраң',
'тырби',
'тыржи',
'тырию',
'тырла',
'тырна',
'тырси',
'тырти',
'тырыл',
'тырым',
'тырқи',
'тырқы',
'тысқы',
'тытыр',
'тышти',
'тыюшы',
'тығыл',
'тығын',
'тықыл',
'тыңай',
'тыңаю',
'тыңқи',
'тібен',
'тігіл',
'тігін',
'тігіс',
'тізбе',
'тізде',
'тізіл',
'тізіс',
'тікей',
'тікею',
'тікпе',
'тікте',
'тікше',
'тікши',
'тілен',
'тілес',
'тілет',
'тілме',
'тілше',
'тілші',
'тіліс',
'тінді',
'тінту',
'тірел',
'тірен',
'тірес',
'тірет',
'тірке',
'тірне',
'тіріл',
'тісең',
'тісте',
'тітір',
'түгес',
'түгет',
'түзел',
'түзен',
'түзер',
'түзес',
'түзет',
'түзең',
'түзіл',
'түйме',
'түйне',
'түйре',
'түйіл',
'түйіс',
'түкси',
'түкте',
'түкше',
'түкір',
'түлет',
'түлеу',
'түнер',
'түнес',
'түнет',
'түнеу',
'түпті',
'түрле',
'түрпе',
'түрпі',
'түрти',
'түрту',
'түріл',
'түріс',
'түсіс',
'түтен',
'түтет',
'түтеу',
'түтік',
'түтіл',
'түтіс',
'түюлі',
'түюші',
'түңіл',
'тұбыр',
'тұжыр',
'тұзда',
'тұзшы',
'тұйти',
'тұйғы',
'тұлан',
'тұлда',
'тұлды',
'тұлым',
'тұлып',
'тұмақ',
'тұмса',
'тұмша',
'тұнба',
'тұнғы',
'тұпар',
'тұпыл',
'тұпыр',
'тұрба',
'тұрла',
'тұрпа',
'тұрыл',
'тұрым',
'тұрыс',
'тұсал',
'тұсас',
'тұсат',
'тұспа',
'тұтан',
'тұтат',
'тұтау',
'тұтпа',
'тұтты',
'тұтыл',
'тұтым',
'тұтын',
'тұтыс',
'тұшти',
'тұшым',
'тұшын',
'тұшыр',
'тұщыт',
'тұғжи',
'тұқан',
'тұқит',
'тұқию',
'тұқия',
'тұқпа',
'тұқши',
'тұқыр',
'тәбет',
'тәйке',
'тәйла',
'тәйір',
'тәлип',
'тәлти',
'тәмпи',
'тәпек',
'тәуап',
'тәует',
'тәуіп',
'тәшек',
'тәшке',
'тәшти',
'төгіл',
'төгін',
'төзім',
'төкпе',
'төлде',
'төлді',
'төлен',
'төлет',
'төмпе',
'төніс',
'төпет',
'төпеу',
'төпеш',
'төрле',
'төсег',
'төсел',
'төсем',
'төсен',
'төсес',
'төсет',
'төсеу',
'төсте',
'төсти',
'төсті',
'төтеу',
'уасыл',
'уатыл',
'уағда',
'уақап',
'улант',
'улану',
'упияз',
'урала',
'ушеуі',
'ушығу',
'уызда',
'уызды',
'уылжу',
'уылжы',
'уыста',
'уытта',
'уышты',
'уықта',
'уілде',
'уәжге',
'уәжді',
'уәзін',
'фабий',
'фагот',
'факел',
'факир',
'факті',
'фальц',
'фанза',
'фасад',
'фасон',
'фауна',
'фаянс',
'фенал',
'ферзь',
'фетиш',
'фибра',
'фикус',
'филос',
'финал',
'фирма',
'фланг',
'флора',
'фляга',
'форте',
'форум',
'фотон',
'фраза',
'фреза',
'френч',
'фугас',
'фужер',
'функц',
'хазар',
'хайыр',
'хакас',
'халат',
'халуа',
'халфе',
'харчо',
'хасіл',
'хатың',
'хаула',
'хижра',
'хикая',
'химик',
'хинин',
'хисса',
'хорал',
'хорда',
'хорей',
'хунта',
'хурал',
'хутор',
'цапфа',
'центр',
'циркі',
'цитат',
'цыган',
'честь',
'чечен',
'чуваш',
'чукча',
'шабан',
'шабақ',
'шабыл',
'шабын',
'шабыс',
'шадыр',
'шажар',
'шайба',
'шайка',
'шайла',
'шайма',
'шайна',
'шайхы',
'шайыл',
'шайыр',
'шайыс',
'шайға',
'шайқа',
'шайқы',
'шалам',
'шалан',
'шалап',
'шалау',
'шалаш',
'шалаң',
'шалжи',
'шалпы',
'шалым',
'шалғы',
'шалқу',
'шалқы',
'шаман',
'шамот',
'шанаш',
'шанақ',
'шанда',
'шанду',
'шанды',
'шаншы',
'шапақ',
'шаппа',
'шапта',
'шапшу',
'шапшы',
'шапқы',
'шаржы',
'шарик',
'шарла',
'шарлы',
'шарпу',
'шарпы',
'шарта',
'шарти',
'шарыл',
'шарға',
'шасси',
'шатас',
'шатақ',
'шатун',
'шатыл',
'шаула',
'шауыш',
'шахар',
'шахта',
'шашал',
'шашпа',
'шашыл',
'шашыс',
'шаяды',
'шағыс',
'шақат',
'шақпа',
'шақпы',
'шақши',
'шақыл',
'шаңар',
'шаңда',
'шаңит',
'шаңыт',
'шаңғы',
'шаңқи',
'шегер',
'шеккі',
'шекте',
'шекши',
'шекіс',
'шелде',
'шелді',
'шелит',
'шелию',
'шелти',
'шенде',
'шенел',
'шенеп',
'шенес',
'шенеу',
'шепір',
'шепөк',
'шерле',
'шерми',
'шерти',
'шерту',
'шерік',
'шетеу',
'шетпе',
'шешей',
'шешіл',
'шешін',
'шешіс',
'шидей',
'шидем',
'шидең',
'шидию',
'шиеле',
'шикіл',
'шилан',
'шиман',
'шинел',
'шипая',
'шипаң',
'шипию',
'шипың',
'шираз',
'ширал',
'шират',
'ширау',
'шитті',
'шифер',
'шифон',
'шифөр',
'шихта',
'шиыру',
'шиқыл',
'шкала',
'шквал',
'шланг',
'шобыр',
'шодыр',
'шойқи',
'шолан',
'шолжи',
'шолти',
'шомда',
'шомпы',
'шомшы',
'шомыл',
'шомыр',
'шомыт',
'шонти',
'шопан',
'шопақ',
'шопыл',
'шорай',
'шорлы',
'шоршу',
'шоршы',
'шорыл',
'шоссе',
'шотал',
'шотта',
'шотты',
'шотын',
'шотыр',
'шотқа',
'шофер',
'шошай',
'шошаю',
'шошаң',
'шошти',
'шошын',
'шошыт',
'шоғал',
'шоған',
'шоғыл',
'шоқал',
'шоқат',
'шоқаю',
'шоқақ',
'шоқаң',
'шоқит',
'шоқта',
'шоқши',
'шоқын',
'шоқыр',
'шоқыс',
'шоқыт',
'шоңқа',
'шоңқи',
'шпана',
'шпиль',
'шпион',
'шприц',
'шпрот',
'штамп',
'штарф',
'штиль',
'штраф',
'шулан',
'шулас',
'шулат',
'шулау',
'шулеу',
'шуруп',
'шусыз',
'шыбыш',
'шыбық',
'шыдам',
'шыдас',
'шыдат',
'шыжақ',
'шыжыл',
'шыжым',
'шыжыт',
'шыжың',
'шылам',
'шылан',
'шылқы',
'шымал',
'шымшы',
'шымқа',
'шынар',
'шында',
'шыпта',
'шыпшы',
'шырла',
'шырма',
'шырпы',
'шырти',
'шырша',
'шырыл',
'шырым',
'шырыш',
'шырға',
'шырқа',
'шытыл',
'шытыс',
'шығым',
'шығыр',
'шықыл',
'шыңда',
'шыңды',
'шыңыл',
'шідер',
'шілби',
'шілия',
'шілте',
'шілти',
'шілше',
'шірен',
'шірес',
'шірік',
'шіріт',
'шүйгу',
'шүйгі',
'шүйде',
'шүйке',
'шүйки',
'шүйіл',
'шүйір',
'шүкур',
'шүкін',
'шүлен',
'шүмек',
'шүрек',
'шүрпе',
'шүрти',
'шүрши',
'шүуле',
'шүңки',
'шұбал',
'шұбат',
'шұбау',
'шұбыр',
'шұлық',
'шұлғи',
'шұлғу',
'шұлғы',
'шұнай',
'шұнти',
'шұрай',
'шұтыр',
'шұқши',
'шұқын',
'шұқыт',
'шәлек',
'шәлти',
'шәлту',
'шәнию',
'шәрке',
'шәулі',
'шәуши',
'шәуіл',
'шәшке',
'шәңкі',
'шөгел',
'шөген',
'шөгер',
'шөгін',
'шөгір',
'шөжім',
'шөкпе',
'шөлде',
'шөлең',
'шөлке',
'шөмей',
'шөмею',
'шөмең',
'шөмию',
'шөміш',
'шөпте',
'шөтке',
'шөңет',
'щетка',
'ыдағы',
'ыдыра',
'ыжғыс',
'ызбан',
'ызбар',
'ызғыз',
'ызғын',
'ызғыр',
'ызғыт',
'ылдым',
'ылпың',
'ымдас',
'ымдау',
'ымыра',
'ымқыр',
'ындын',
'ынтам',
'ырбаң',
'ырбит',
'ырбию',
'ыржақ',
'ыржаң',
'ыржит',
'ыржию',
'ыржың',
'ырзық',
'ырсит',
'ырсию',
'ырсыл',
'ырсыт',
'ыртын',
'ыршыт',
'ырымы',
'ырғал',
'ырғап',
'ырғас',
'ырғат',
'ырғау',
'ырғаң',
'ырғын',
'ырғыс',
'ырғыт',
'ыспат',
'ыстал',
'ыстат',
'ыстау',
'ысылт',
'ысылу',
'ысыну',
'ысыту',
'ысқат',
'ысқыр',
'ысқыш',
'ытылу',
'ытқыт',
'ыштан',
'ышқын',
'ығырт',
'ығысу',
'ықлас',
'ықпыл',
'ықсыр',
'ықтат',
'ықтын',
'ықтыр',
'ыңсыз',
'ыңқит',
'ыңқыл',
'эвенк',
'экзот',
'элита',
'эмаль',
'юзныс',
'ізбес',
'іздел',
'ізден',
'іздес',
'іздет',
'ізсіз',
'ізшіл',
'ілбит',
'ілбию',
'ілбіс',
'ілбіт',
'ілгек',
'ілгір',
'ілгіш',
'ілесу',
'ілмек',
'ілмию',
'іліми',
'індек',
'індет',
'інжуг',
'інжір',
'іркіл',
'іркін',
'іркіс',
'іркіт',
'ірмек',
'іртік',
'іріту',
'іскек',
'іслам',
'ісмер',
'іспек',
'іспет',
'іссіз',
'істел',
'істет',
'істік',
'істір',
'ісшең',
'ісшіл',
'ісіну',
'ісірт',
'ісіру',
'ітуге',
'ішкіз',
'ішкіш',
'іштес',
'іңген',
'іңкәл',
'ғадет',
'ғаділ',
'ғазап',
'ғазел',
'ғайыс',
'ғапіл',
'ғарап',
'ғарза',
'ғаруз',
'ғарыз',
'ғарыш',
'ғауға',
'ғилаж',
'ғұжыл',
'ғұзыр',
'ғұрлы',
'ғұсыл',
'ғұшыр',
'қабар',
'қабыз',
'қабын',
'қабыр',
'қабыс',
'қабық',
'қадал',
'қадан',
'қадас',
'қадат',
'қадау',
'қадаң',
'қадер',
'қадет',
'қадис',
'қадым',
'қажал',
'қажар',
'қажас',
'қажат',
'қажау',
'қажақ',
'қажаң',
'қажыт',
'қазап',
'қазат',
'қазди',
'қазша',
'қазыл',
'қазын',
'қазыс',
'қайза',
'қайла',
'қайми',
'қайна',
'қайны',
'қайра',
'қайыз',
'қайыл',
'қайыт',
'қайқи',
'қалан',
'қалас',
'қалат',
'қалақ',
'қалби',
'қалжа',
'қалпе',
'қалти',
'қалша',
'қалши',
'қалын',
'қалыс',
'қалыш',
'қалғу',
'қалғы',
'қалқи',
'қалқу',
'қалқы',
'қамас',
'қамат',
'қамақ',
'қамба',
'қамда',
'қамды',
'қампи',
'қамту',
'қамты',
'қамыр',
'қамыт',
'қанал',
'қанар',
'қанау',
'қансы',
'қаныс',
'қапаш',
'қапер',
'қапта',
'қапыл',
'қапыс',
'қарал',
'қарам',
'қаран',
'қарас',
'қарат',
'қараю',
'қарақ',
'қарбу',
'қарбы',
'қаржу',
'қарла',
'қарма',
'қарпу',
'қарпы',
'қарун',
'қаршы',
'қарыл',
'қарыт',
'қарыш',
'қарғу',
'қасам',
'қасас',
'қасат',
'қасаң',
'қасер',
'қасша',
'қасыл',
'қасыс',
'қасыт',
'қасқи',
'қатай',
'қатап',
'қатаю',
'қатта',
'қатыл',
'қатым',
'қатыр',
'қатыс',
'қауди',
'қаужа',
'қаужи',
'қауза',
'қаула',
'қаума',
'қаупи',
'қауса',
'қауыз',
'қауыш',
'қауға',
'қауқи',
'қашал',
'қашар',
'қашас',
'қашат',
'қашау',
'қашақ',
'қашаң',
'қашым',
'қаюла',
'қаған',
'қағас',
'қағат',
'қағыл',
'қағын',
'қағыр',
'қағыт',
'қақай',
'қақал',
'қақас',
'қақаю',
'қақақ',
'қақаң',
'қақит',
'қақию',
'қақса',
'қақсу',
'қақсы',
'қақта',
'қақши',
'қақшу',
'қақшы',
'қақыр',
'қаққы',
'қаңан',
'қаңар',
'қаңат',
'қаңбе',
'қаңсу',
'қаңсы',
'қаңғу',
'қаңғы',
'қаңқа',
'қаңқи',
'қидыр',
'қилан',
'қилат',
'қилау',
'қинал',
'қинас',
'қинат',
'қинау',
'қипақ',
'қипаң',
'қирай',
'қират',
'қирау',
'қираң',
'қисай',
'қисаю',
'қитар',
'қитит',
'қитию',
'қитта',
'қитың',
'қиылу',
'қиғай',
'қиғыз',
'қиғыр',
'қиқай',
'қиқаю',
'қиқақ',
'қиқаң',
'қиқит',
'қиқию',
'қиқыл',
'қиқым',
'қобди',
'қобыр',
'қодай',
'қодаю',
'қодаң',
'қодит',
'қодию',
'қожаң',
'қожыр',
'қозба',
'қозда',
'қозға',
'қойла',
'қойлы',
'қойыл',
'қойым',
'қойыр',
'қойыс',
'қойың',
'қолай',
'қолау',
'қомақ',
'қомда',
'қомпи',
'қомша',
'қомыт',
'қонба',
'қонжи',
'қоным',
'қопай',
'қопал',
'қопар',
'қопаю',
'қопаң',
'қопсу',
'қопсы',
'қораз',
'қорам',
'қорби',
'қорди',
'қорды',
'қоржи',
'қорла',
'қорти',
'қорша',
'қорыл',
'қорыс',
'қорыт',
'қоспа',
'қосыс',
'қотар',
'қотта',
'қошта',
'қоюла',
'қояла',
'қоқай',
'қоқаю',
'қоқақ',
'қоқаң',
'қоқию',
'қоқсу',
'қоқсы',
'қоқта',
'қоқты',
'қоқши',
'қоқым',
'қоқыт',
'қоңай',
'қоңаш',
'қоңаю',
'қоңды',
'қоңсу',
'қоңыс',
'қоңқи',
'қуала',
'қуант',
'қуану',
'қуарт',
'қуару',
'қуақы',
'қудыр',
'қузал',
'қузау',
'қулан',
'қунау',
'қурат',
'қурау',
'қусыр',
'қутың',
'қуушы',
'қушит',
'қушию',
'қушық',
'қуыну',
'қуырт',
'қуыру',
'қуғыз',
'қыдит',
'қыдыр',
'қыдың',
'қыжыл',
'қыжым',
'қыжыр',
'қызар',
'қызша',
'қызыс',
'қылап',
'қылда',
'қылжи',
'қылми',
'қылпы',
'қылта',
'қылти',
'қылша',
'қылғы',
'қылқа',
'қылқи',
'қымта',
'қымты',
'қымқа',
'қынап',
'қырат',
'қыраң',
'қыржи',
'қыржы',
'қырла',
'қырма',
'қырна',
'қырой',
'қырпа',
'қырпу',
'қырпы',
'қырти',
'қырту',
'қыршу',
'қыршы',
'қырыс',
'қырғы',
'қысам',
'қысас',
'қысау',
'қысаң',
'қыспа',
'қысыл',
'қысыс',
'қысық',
'қытта',
'қытым',
'қытыр',
'қышат',
'қышау',
'қышта',
'қышшы',
'қышыл',
'қышын',
'қышыр',
'қышыс',
'қышыт',
'қыңба',
'қыңсы',
'құбаң',
'құбыр',
'құдақ',
'құдит',
'құдию',
'құдыр',
'құжақ',
'құжаң',
'құжыр',
'құжың',
'құзар',
'құзыр',
'құйма',
'құйти',
'құйыл',
'құйыс',
'құйғу',
'құйғы',
'құйқа',
'құлас',
'құлат',
'құлау',
'құлди',
'құлду',
'құлпы',
'құлық',
'құмса',
'құмыр',
'құмық',
'құнай',
'құнар',
'құнда',
'құнжи',
'құнти',
'құныс',
'құнға',
'құпаш',
'құпта',
'құраз',
'құрай',
'құрам',
'құрап',
'құрас',
'құрат',
'құраң',
'құржи',
'құрла',
'құрма',
'құрыз',
'құрыл',
'құрыс',
'құрға',
'құста',
'құсшы',
'құсқы',
'құтай',
'құтан',
'құтаю',
'құтпа',
'құтыл',
'құтыр',
'құшыр',
'құшыс',
'құюшы',
'құқай',
'ңелеу',
'үгілт',
'үгілу',
'үгіту',
'үделі',
'үдеме',
'үдету',
'үзгіз',
'үздір',
'үзілт',
'үзілу',
'үйгіз',
'үйдек',
'үйдір',
'үйеле',
'үйеме',
'үйкел',
'үйкен',
'үйкес',
'үйкеу',
'үйкөк',
'үйлен',
'үймек',
'үйсіз',
'үйтам',
'үйтек',
'үйтіл',
'үйтіс',
'үйілу',
'үйіру',
'үйісу',
'үккіз',
'үккіш',
'үктір',
'үкіле',
'үлгер',
'үлгіл',
'үлгір',
'үлесу',
'үлкей',
'үлкею',
'үлпат',
'үлпек',
'үмбет',
'үмеле',
'үндес',
'үндет',
'үндеу',
'үнімі',
'үптел',
'үптес',
'үптет',
'үптеу',
'үргіз',
'үргін',
'үргіш',
'үрдек',
'үрдір',
'үрият',
'үркін',
'үркіс',
'үркіт',
'үрлен',
'үрлес',
'үрлет',
'үрлеу',
'үрмен',
'үрмет',
'үрпая',
'үрпет',
'үрпит',
'үрпию',
'үскір',
'үстек',
'үстен',
'үстет',
'үстіп',
'үсіну',
'үсіру',
'үсіту',
'үшаяқ',
'үшақа',
'үштіс',
'үшқат',
'үңгіп',
'үңгіт',
'үңілт',
'үңілу',
'ұдайы',
'ұждан',
'ұзайт',
'ұзарт',
'ұзару',
'ұзату',
'ұйбай',
'ұймыш',
'ұйпат',
'ұйпау',
'ұйтқу',
'ұйтқы',
'ұйыма',
'ұйысу',
'ұйыту',
'ұйығу',
'ұйғар',
'ұйғақ',
'ұйқай',
'ұласу',
'ұлдай',
'ұлпақ',
'ұлтар',
'ұлылы',
'ұлғай',
'ұлғат',
'ұлғаю',
'ұлқат',
'ұмсын',
'ұмтақ',
'ұмтыл',
'ұмыту',
'ұнату',
'ұнтал',
'ұнтау',
'ұнтақ',
'ұрала',
'ұралы',
'ұрбаң',
'ұрлан',
'ұрлас',
'ұрлат',
'ұрлау',
'ұрмай',
'ұрсыс',
'ұртта',
'ұрыну',
'ұрғыз',
'ұсату',
'ұстал',
'ұстам',
'ұстан',
'ұстас',
'ұстат',
'ұстын',
'ұсынт',
'ұсқын',
'ұттыр',
'ұтыла',
'ұтылу',
'ұтыға',
'ұтқыз',
'ұшпақ',
'ұштал',
'ұштап',
'ұштас',
'ұштат',
'ұштау',
'ұшыну',
'ұшырт',
'ұшқас',
'ұялас',
'ұялат',
'ұялау',
'ұяшық',
'ұяңда',
'ұғлан',
'ұғушы',
'ұғыну',
'ұқпас',
'ұқсат',
'ұқсау',
'ұқтыр',
'ұққыз',
'ұққыш',
'ұңғыл',
'ұңғыр',
'ұңғыт',
'әбжат',
'әбжіл',
'әбзел',
'әдіре',
'әжеке',
'әжеле',
'әйбат',
'әйдай',
'әйдік',
'әйкел',
'әйнек',
'әкелд',
'әкелу',
'әлейк',
'әлпет',
'әлпеш',
'әлтек',
'әлует',
'әлхам',
'әмиян',
'әмпей',
'әміре',
'әмісе',
'әнбие',
'әндет',
'әнеки',
'әнеуг',
'әнмен',
'әннен',
'әнтек',
'әншіл',
'әнқой',
'әпкел',
'әпкіш',
'әптер',
'әреке',
'әрлен',
'әрлеу',
'әрсіз',
'әртін',
'әруақ',
'әріде',
'әспет',
'әсілі',
'әтейі',
'әуезе',
'әуейі',
'әуеле',
'әуест',
'әукөм',
'өбере',
'өжіре',
'өзгер',
'өздік',
'өйдеу',
'өктеу',
'өкінт',
'өкірт',
'өкіру',
'өлара',
'өлесі',
'өлшен',
'өлшес',
'өнгіш',
'өнімі',
'өпшін',
'өргіз',
'өрдеш',
'өреле',
'өреше',
'өрлен',
'өрлет',
'өрлік',
'өртел',
'өртен',
'өртес',
'өртет',
'өртең',
'өрулі',
'өршіл',
'өршіт',
'өскін',
'өскіш',
'өсуге',
'өсуде',
'өткер',
'өткіз',
'өткін',
'өттік',
'өштен',
'өштес',
'өшігу',
'өшірт',
'өңдел',
'өңден',
'өңдеп',
'өңдет',
'өңкит',
'өңкию',
'өңмең',
'өңсіз',
'Iсмет',
'Абзал',
'Агзам',
'Адиль',
'Айбар',
'Айдар',
'Айдос',
'Айжан',
'Айзия',
'Айман',
'Айнур',
'Алтай',
'Амина',
'Анвар',
'Ануар',
'Арқат',
'Арман',
'Арнур',
'Асель',
'Асима',
'Асқар',
'Аскат',
'Ахмет',
'Ахрам',
'Бакир',
'Бақыт',
'Батес',
'Берен',
'Берик',
'Божей',
'Бокей',
'Бошай',
'Ғабит',
'Ғайни',
'Данеш',
'Дария',
'Демеу',
'Ділда',
'Думан',
'Дурия',
'Едіге',
'Ерден',
'Ердос',
'Ержан',
'Ермек',
'Жайна',
'Жакия',
'Жамал',
'Жанар',
'Жания',
'Жанша',
'Жарас',
'Жәлел',
'Жетес',
'Жибек',
'Жолан',
'Жуман',
'Жунис',
'Жупар',
'Жусип',
'Зайыр',
'Зейне',
'Зияда',
'Илияс',
'Кадыр',
'Кажым',
'Кайым',
'Калка',
'Қанат',
'Кемел',
'Кәрім',
'Кобей',
'Күнке',
'Лейла',
'Мажит',
'Малик',
'Манап',
'Манар',
'Манас',
'Медет',
'Медеу',
'Мирас',
'Монке',
'Мурат',
'Мухит',
'Надир',
'Накып',
'Онгар',
'Разия',
'Райыс',
'Ракия',
'Ракым',
'Рауан',
'Рахат',
'Рашит',
'Рухия',
'Сабаз',
'Сабит',
'Сагит',
'Саида',
'Сайын',
'Сақып',
'Самат',
'Сания',
'Сейiт',
'Серке',
'Сугiр',
'Суйеу',
'Сырым',
'Тагай',
'Тайыр',
'Талип',
'Уайыс',
'Уакап',
'Умбет',
'Уркия',
'Хамза',
'Хамит',
'Хасен',
'Хафиз',
'Шарип',
'Ыксан',
'Ыскак',
'аблық',
'айбық',
'айдік',
'айрық',
'аптық',
'батық',
'безік',
'бишік',
'болық',
'борық',
'булық',
'быдық',
'бырық',
'бытық',
'бірік',
'бүрік',
'бұйық',
'бәдік',
'бәпік',
'гірік',
'демік',
'долық',
'дулық',
'дымық',
'дәнік',
'ендік',
'ентік',
'ершік',
'жалық',
'жамық',
'жанық',
'жасық',
'жатық',
'жауық',
'жолық',
'жосық',
'жынық',
'жыпық',
'жінік',
'жітік',
'жұлық',
'жөнік',
'зарық',
'зауық',
'зерік',
'зорық',
'кезік',
'кесік',
'кеуік',
'кешік',
'килік',
'кірік',
'күжік',
'кәдік',
'кәжік',
'кәнік',
'көзік',
'көнік',
'көсік',
'масық',
'мауық',
'милық',
'митық',
'мишық',
'молық',
'мырық',
'мөжік',
'налық',
'омбық',
'оптық',
'орнық',
'оқтық',
'парық',
'рызық',
'сарық',
'семік',
'сетік',
'собық',
'солық',
'сорық',
'сулық',
'сірік',
'сүрік',
'сұрық',
'талық',
'танық',
'тапық',
'тарық',
'титық',
'тозық',
'тонық',
'торық',
'тотық',
'тырық',
'тілік',
'түсік',
'тұлық',
'тұрық',
'тұтық',
'төлік',
'хұқық',
'шабық',
'шалық',
'шашық',
'шебік',
'шелік',
'шилық',
'ширық',
'шошық',
'шулық',
'шыжық',
'шылық',
'шынық',
'шырық',
'шұжық',
'ыржық',
'іздік',
'ірмік',
'қалық',
'қамық',
'қатық',
'қитық',
'қодық',
'қожық',
'қосық',
'қыпық',
'қытық',
'құнық',
'құсық',
'үрлік',
'үштік',
'ұйлық',
'ұлдық',
'ұршық',
'ұштық',
'әздік',
'әйзік',
'әулік',
'өршік',
'өштік',
'төсек',
'керім',
'пәлте',
'жинақ',
'дүкен',
'ақкіс',
'пиғыл',
'көңіл',
'бөлім',
'дауыс',
'күміс',
'ұсқын',
'шылау',
'әлпет',
'нәсіл',
'екпін',
'нышан',
'серік',
'сүйеу',
'серік',
'ширек',
'ойпат',
'зұлым',
'тесік',
'содыр',
'ісмер',
'қайшы',
'құрық',
'бөрік',
'түлкі',
'нақыл',
'көрме',
'үйшік',
'дақыл',
'тырма',
'шырша',
'дүсір',
'шумақ',
'ұйқас',
'өңмен',
'томар',
  'батыр',
  'халық',
  'алтын',
  'сезім',
  'құдай',
  'уақыт',
  'жүрек',
  'жырау',
  'сойыл',
  'сегіз',
  'дүние',
  'батыс',
  'жалын',
  'темір',
  'қайғы',
  'ханым',
  'жібек',
  'қоңыр',
  'бұлақ',
  'ғалым',
  'мырза',
  'қауіп',
  'жасын',
  'құлаш',
  'билік',
  'жазық',
  'кітап',
  'әрине',
  'үнемі',
  'әдемі',
  'оқушы',
  'әдеби',
  'спорт',
  'ұдайы',
  'ұйтқы',
  'әсіре',
  'апорт',
  'сарын',
  'орман',
  'сәтен',
  'қоныс',
  'ноқат',
  'бұзау',
  'көрші',
  'жарық',
  'бауыр',
  'нобай',
  'ақкіс',
  'кәуап',
  'жетім',
  'әулет',
  'нәрсе',
  'оқиға',
  'құпия',
  'жоңқа',
  'кіреу',
  'зәуде',
  'жауын',
  'бейім',
  'мінез',
  'қаріп',
  'күйеу',
  'жайық',
  'ауқат',
  'қарыс',
  'ыңғай',
  'қайың',
  'жесір',
  'бәден',
  'тұнба',
  'бүлік',
  'қауым',
  'ұшқын',
  'мұрат',
  'бөлме',
  'аруақ',
  'дария',
  'күмән',
  'сүйек',
  'ұлтан',
  'жігер',
  'сурет',
  'адуын',
  'шадыр',
  'тұлым',
  'нөкер',
  'ғұмыр',
  'биязы',
  'қайын',
  'басшы',
  'жеміс',
  'көрпе',
  'өңмен',
  'ғашық',
  'бөгде',
  'мұрын',
  'шатыр',
  'әулие',
  'бөгет',
  'қойшы',
  'сүрең',
  'жібек',
  'өркеш',
  'жеңіс',
  'аңғал',
  'мұрша',
  'соқыр',
  'ұрпақ',
  'болыс',
  'сауыт',
  'уайым',
  'қоғам',
  'тамыз',
  'айғыр',
  'сиқыр',
  'тұлға',
  'ұрлық',
  'сәуле',
  'тамшы',
  'мүсін',
  'шілде',
  'көсем',
  'бұзық',
  'дөкір',
  'төбет',
  'қимыл',
  'күзен',
  'оймақ',
  'құрбы',
  'доңыз',
  'қоңсы',
  'мүйіз',
  'тоғай',
  'құзар',
  'монша',
  'жорық',
  'нәпсі',
  'тұзақ',
  'алғыс',
  'молда',
  'шұбақ',
  'айтыс',
  'таспа',
  'шырай',
  'қария',
  'рәсуа',
  'көбік',
  'тасшы',
  'облыс',
  'пейіл',
  'реніш',
  'құман',
  'шабын',
  'астық',
  'дабыл',
  'дорба',
  'жолақ',
  'кәсіп',
  'мешіт',
  'мұхит',
  'шабыт',
  'хатшы',
  'дүбір',
  'масыл',
  'торай',
  'бипаз',
  'сипат',
  'шалғы',
  'тәлім',
  'таным',
  'қаржы',
  'сақшы',
  'жауыз',
  'кейіп',
  'шаңғы',
  'сөкет',
  'түбек',
  'тұсау',
  'уәкіл',
  'бұрыш',
  'елдік',
  'сүтші',
  'жүйке',
  'нөпір',
  'түнек',
  'мирас',
  'тірек',
  'қорек',
  'тырна',
  'бүгін',
  'қырау',
  'малшы',
  'қауын',
  'күрте',
  'шопан',
  'бесік',
  'шодыр',
  'бәйге',
  'ізбес',
  'күбір',
  'лебіз',
  'түйір',
  'диқан',
  'шырақ',
  'құрал',
  'үйрек',
  'бақсы',
  'думан',
  'жүлде',
  'залым',
  'қобыз',
  'түрме',
  'тұман',
  'нойыс',
  'көпір',
  'негіз',
  'сыңар',
  'үңгір',
  'ұстаз',
  'өрнек',
  'мысал',
  'тәсіл',
  'мұнай',
  'нүкте',
  'табыс',
  'дәуір',
  'мәнер',
  'ноқта',
  'пәтер',
  'сұйық',
  'тұран',
  'нұсқа',
  'шыдам',
  'үстел',
  'жақұт',
  'сирақ',
  'тақия',
  'ықпал',
  'үстеу',
  'өрмек',
  'сұлба',
  'білек',
  'күйші',
  'әскер',
  'тұғыр',
  'желім',
  'нарық',
  'сызба',
  'тұмау',
  'тәңір',
  'қашыр',
  'өсиет',
  'азғын',
  'зейін',
  'дәліз',
  'жусан',
  'күріш',
  'түлек',
  'мұржа',
  'төлем',
  'үрдіс',
  'айбын',
  'дәнек',
  'жарыс',
  'заңшы',
  'түйме',
  'нымша',
  'мәйіт',
  'оташы',
  'мәтел',
  'нәзір',
  'пияла',
  'нәсіп',
  'пойыз',
  'нөсер',
  'шұжық',
  'әмиян',
  'ұнтақ',
  'оқтау',
  'ризық',
  'құрма',
  'соғым',
  'қоспа',
  'ғұсыл',
  'індет',
  'сірне',
  'шүмек',
  'сүйел',
  'жазба',
  'пайыз',
  'сәбіз',
  'сөмке',
  'өлшем',
  'тоқаш',
  'сүзгі',
  'құжат',
  'тізбе',
  'дарын',
  'белгі',
  'тұлып',
  'салық',
  'әмісе',
  'төзім',
  'хикая',
  'шабыс',
  'түйін',
  'қадір',
  'бағыт',
  'шапқы',
  'ызғар',
  'аштық',
  'сүлгі',
  'шұбат',
  'сүзбе',
  'сыңсу',
  'ғарыш',
  'сынақ',
  'шұлық',
  'қамыр',
  'ілгек',
  'сағыз',
  'рәсім',
  'керім',
  'пәлте',
  'жинақ',
  'дүкен',
  'пиғыл',
  'көңіл',
  'дауыс',
  'күміс',
  'ұсқын',
  'шылау',
  'әлпет',
  'нәсіл',
  'екпін',
  'нышан',
  'серік',
  'сүйеу',
  'серік',
  'ширек',
  'ойпат',
  'зұлым',
  'тесік',
  'содыр',
  'ісмер',
  'қайшы',
  'құрық',
  'бөрік',
  'түлкі',
  'нақыл',
  'көрме',
  'үйшік',
  'дақыл',
  'тырма',
  'дүсір',
  'шумақ',
  'ұйқас',
  'өштік',
  'жосық',
  'алтын',
  'сойыл',
  'дүние',
  'қалет',
]